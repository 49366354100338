import {createReducer} from 'reduxsauce';
import {PaymentTypes} from './actions';

export interface IPaymentState {
    amount: any,
    error: string,
    status: string,
    loading: boolean,
    encriptAmount: string,
}

export const paymentR: IPaymentState = {
    amount: {},
    error: "",
    status: "",
    loading: false,
    encriptAmount:""
};

export const amount = (state) => {
    return {...state, status: "", loading: true};
};

export const amountSuccess = (state, action) => {
    return {...state, amount: action.data, encriptAmount:action.data.encriptAmount, status: "", loading: false};
};

export const amountFailure = (state, action) => {
    return {...state, error: action.error, amount: {},encriptAmount:"", status: "failed", loading: false};
};

export const handlers = {
    [PaymentTypes.AMOUNT_START]: amount,
    [PaymentTypes.AMOUNT_SUCCESS]: amountSuccess,
    [PaymentTypes.AMOUNT_FAILURE]: amountFailure,
};

export default createReducer(paymentR, handlers);
