import React, { useState, useEffect } from "react";
import i18next from "i18next";
import SidePanel from "../../common/sidePanel";
import LocationSearchInput from "../../locationSearchInput/container/locationSearchInput"
import { createWebOrderLineItems, decryptCryptr, getbasketLineItems, getBasketStore, getDataLineItems, getLocalStorageObject, getShippingCity, isLoggedin, setStoreToBasket } from "../../../helpers/helpers";
import { fetchClickAnCollectStores } from "../../../utils/constants/service";
import { BRAND_ID, COUNTRY, ORDER_STATUS, STORAGE_BASKET_INDEX, STORE_DELIVERY, VALIDATE_DISCCOUNT } from "../../../utils/constants/variables";

interface Props {
    handleClose: () => void,
    addToCart: () => void,
    handleError: (text) => void,
    createOrder: (body) => void,
    data?: any,
    address?: any,
}

const SelectStore = ({ handleClose, addToCart, handleError, createOrder, data, address }: Props) => {
    const shippingCity = getShippingCity()
    const [location, setLoction] = useState(shippingCity);

    useEffect(() => {
        if (location) {
            const basketStoreInfon = getBasketStore();
            const basketLineItems = basketStoreInfon.storeDelivery === 'DIS'
                ? [...getbasketLineItems(), ...getDataLineItems(data)]
                : [...getDataLineItems(data)];
            if (STORE_DELIVERY === '1') {
                fetchClickAnCollectStores(
                    {
                        basketLineItems
                    },
                    location,
                    STORE_DELIVERY === '1' ? { shipment: true } : null
                ).then(res => {
                    const store = Array.isArray(res) ? res.find(item => item.shipment && item.hasBasket) : undefined;
                    if (store) {
                        setStoreToBasket({ StoreInfo: store, storeDelivery: 'DIS' });
                        setShippingCityAndAddToCart();
                    } else {
                        handleError(i18next.t('locator.11'));
                    }
                    if (isLoggedin()) {
                        const basket = getLocalStorageObject(STORAGE_BASKET_INDEX);
                        const validateDiscount = getLocalStorageObject(VALIDATE_DISCCOUNT);
                        const storeCode = basket.store && basket.store.StoreInfo && basket.store.StoreInfo.storeCode || ''
                        const { webOrderLineItems } = createWebOrderLineItems(basket);
                        const selectedBillingAddress = address.find((item) => item.addressType === 'BILL' && item.isDefault === true);
                        const selectedShippingAddress = address.find((item) => item.addressType === 'SHIP' && item.isDefault === true);
                        if (selectedBillingAddress && selectedBillingAddress.clientAddressId) {
                            createOrder({
                                billingAddressId: selectedBillingAddress.clientAddressId,
                                brandId: BRAND_ID,
                                discountCode: validateDiscount.discountCode || "",
                                orderId: null,
                                country: COUNTRY,
                                orderStatus: ORDER_STATUS.PEN,
                                shippingAddressId: selectedShippingAddress.clientAddressId,
                                storeCode,
                                webOrderLineItems,
                                browserId: decryptCryptr("browserId"),
                            });
                        }
                    }
                    handleClose();
                }).catch(e => {
                    handleClose();
                    handleError(i18next.t('locator.11'));
                })
            } else {
                setShippingCityAndAddToCart();
            }
        }
    }, [location])

    const setShippingCityAndAddToCart = () => {
        addToCart();
        getShippingCity(location)
    }

    if (shippingCity) {
        return null;
    }

    return (
        <SidePanel className="location-search-input-popup" handleClose={handleClose}>
            <>
                <h4 className="storeLocator-title">{i18next.t('locator.6')}</h4>
                <div className="side-panel-search-content">
                    <div className="dflex justify-content-between">
                        <LocationSearchInput
                            classN="search-btn"
                            showButton={true}
                            onSubmit={setLoction}
                        />
                    </div>
                </div>
            </>
        </SidePanel>
    );
}

export default SelectStore
