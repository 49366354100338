import React from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import { Link } from "react-router-dom";
import { BASE_API_GATEWAY } from "../../../../utils/constants/service";
import i18next from "i18next";
import {
  getCurrency,
  getDataBySectionAndWidget,
  getLangPrefix,
  replaceComma,
  getProductCategoryName,
} from "../../../../helpers/helpers";
import "./recommendedProducts.scss";
import Photo from "../../../common/product/photo";
import { LazyImage } from "react-lazy-images";
import { connect } from "react-redux";
import { getBanner, getBannerError } from "../../redux/selectors";
import LinkFullUrl from "../../../../shared/linkFullUrl/linkFullUrl";
import DiscountDetail from "../../../common/product/discountDetail";

interface Props {
  recommendedProducts: any;
  sliderType: string;
  banner?: any;
  data?: any;
}

class RecommendedProducts extends React.Component<Props> {
  render() {
    const { recommendedProducts, sliderType, banner, data } = this.props;
    const minCont = recommendedProducts && recommendedProducts.length < 5;
    const params = {
      slidesPerView:
        sliderType === "home" ? 1 : sliderType === "productinfo" ? 3 : 1,
      direction:
        sliderType === "home"
          ? "horizontal"
          : sliderType === "productinfo"
          ? "vertical"
          : "horizontal",
      // init: false,

      breakpoints: {
        382: {
          slidesPerView:
            sliderType === "home"
              ? 2
              : sliderType === "productinfo"
              ? 3
              : sliderType === "blog"
              ? 1
              : 2,
        },
        680: {
          slidesPerView:
            sliderType === "home"
              ? 3
              : sliderType === "productinfo"
              ? 3
              : sliderType === "blog"
              ? 1
              : 3,
        },
        980: {
          slidesPerView:
            sliderType === "home"
              ? 3
              : sliderType === "productinfo"
              ? 3
              : sliderType === "blog"
              ? 1
              : 3,
        },
        1020: {
          slidesPerView: 4,
        },
      },
      speed: 600,
      loop: recommendedProducts && recommendedProducts.length > 5,
      lazy: true,
      autoplay: { delay: 5000 },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      wrapperClass: "swiper-wrapper",
      containerClass: "swiper-container",
      setWrapperSize: true,
      resizeReInit: true,
    };

    return sliderType && sliderType === "home" ? (
      <section className={`recommendedSlidercontent`}>
        <div className="home-container">
          <h4 className="recommendedSlidercontent--title ">
            {i18next.t("product.product.19")}
          </h4>
          {recommendedProducts && recommendedProducts.length ? (
            <Swiper {...params}>
              {recommendedProducts &&
                recommendedProducts.map((item, i) => (
                  <div
                    className="product-item  product-content-sm-product    recommendedSlidercontent--item "
                    key={i}
                  >
                    <LinkFullUrl
                      to={`${getLangPrefix()}${item.canonicalMenu}/${
                        item.canonicalUrl
                      }`}
                    >
                      <div className="product-item-img">
                        <LazyImage
                          src={`${BASE_API_GATEWAY}${item && item.photo}`}
                          alt="product-image"
                          placeholder={({ imageProps, ref }) => (
                            <img
                              ref={ref}
                              src={`${BASE_API_GATEWAY}${
                                item && item.thumbnail
                              }`}
                              alt={imageProps.alt}
                            />
                          )}
                          actual={({ imageProps }) => <img {...imageProps} />}
                        />

                        <DiscountDetail product={item} banner={banner} />

                        <div className="product-item-img-text">
                          <h2 className="product-title">{item.name}</h2>
                          <p className="product-categorye-name">
                            {item.attributes &&
                              item.attributes.length &&
                              getProductCategoryName(item.attributes)}
                          </p>
                          <p className="product-price">
                            <span className="price-text">
                              {i18next.t("catalogue.product.3")}{" "}
                            </span>
                            {item.listPriceAfterDiscount ? (
                              <span>
                                {replaceComma(item.listPriceAfterDiscount)}{" "}
                                {getCurrency(item.currency)}
                              </span>
                            ) : (
                              <span>
                                {replaceComma(item.startingPrice)}{" "}
                                {getCurrency(item.currency)}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="info-box">
                        <div className="front">
                          <h2 className="product-title">{item.name}</h2>
                          <p className="product-categorye-name">
                            {item.attributes &&
                              item.attributes.length &&
                              getProductCategoryName(item.attributes)}
                          </p>
                          <div className="info-box-lg-content">
                            <p className="text">
                              {item.attributes &&
                                item.attributes["General Material"] &&
                                item.attributes["General Material"].length &&
                                item.attributes["General Material"][0]}
                            </p>
                            <p
                              className="text multiline-ellipsis"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></p>
                          </div>
                          <p className="product-price">
                            <span className="price-text">
                              {i18next.t("catalogue.product.3")}{" "}
                            </span>
                            {item.listPriceAfterDiscount ? (
                              <span>
                                <span className="new-price">
                                  {replaceComma(item.listPriceAfterDiscount)}{" "}
                                  {getCurrency(item.currency)}
                                </span>
                              </span>
                            ) : (
                              <span className="new-price">
                                {replaceComma(item.startingPrice)}{" "}
                                {getCurrency(item.currency)}
                              </span>
                            )}
                          </p>
                          <div className="info-box-lg-content">
                            <p className="title-brd">
                              {i18next.t("catalogue.product.4")}
                            </p>
                            <span className="title-brd-image"></span>
                          </div>
                        </div>
                        <div className="back">
                          <p className="btt">
                            {i18next.t("home.collection.discover")}
                          </p>
                        </div>
                      </div>
                    </LinkFullUrl>
                  </div>
                ))}
            </Swiper>
          ) : null}
        </div>
      </section>
    ) : sliderType && sliderType === "productinfo" ? (
      <div className={`vertical-slider ${minCont ? "withoutArrows" : ""}`}>
        <div className="vertical-slider--title">
          {i18next.t("product.product.20")}
        </div>
        {recommendedProducts && recommendedProducts.length ? (
          <Swiper {...params}>
            {recommendedProducts.map((item, i) => (
              <div key={i} className="vertical-slider--products">
                <LinkFullUrl
                  to={`${getLangPrefix()}${item.canonicalMenu}/${
                    item.canonicalUrl
                  }`}
                >
                  <div className="vertical-slider--products--item">
                    <Photo src={item && item.thumbnail} />
                  </div>
                </LinkFullUrl>
              </div>
            ))}
          </Swiper>
        ) : null}
      </div>
    ) : sliderType && sliderType === "blog" ? (
      <Swiper {...params}>
        {recommendedProducts &&
          recommendedProducts.length &&
          recommendedProducts.map((item, i) => (
            <div className="blogsRow--item--small" key={i}>
              <div className="blogsRow--item--small--imgBox">
                {
                  <Link to={`${getLangPrefix() + "/magazine" + "/" + item}`}>
                    {getDataBySectionAndWidget(+item, 1, data[item], "PHOTO")
                      .value ? (
                      <img
                        className="blogsRow--item--small--imgBox--img"
                        src={`${BASE_API_GATEWAY}${
                          getDataBySectionAndWidget(
                            +item,
                            1,
                            data[item],
                            "PHOTO"
                          ).value
                        }`}
                      />
                    ) : (
                      ""
                    )}
                  </Link>
                }
                <div className="blogsRow--item--infoBox">
                  <div className="blogsRow--item--small--infoBox--title">
                    {
                      <p
                        dangerouslySetInnerHTML={{
                          __html: getDataBySectionAndWidget(
                            +item,
                            1,
                            data[item],
                            "TEXT",
                            "Title"
                          ).value,
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Swiper>
    ) : (
      ""
    );
  }
}

const mapStateToProps = (state) => ({
  banner: getBanner(state),
  error: getBannerError(state),
});

export default connect(mapStateToProps)(RecommendedProducts);
