import React, { useState } from "react";
import { connect } from 'react-redux'
import { Dispatch } from "redux";
import { withRouter } from "react-router-dom";
import { AddressTypes, STORE_DELIVERY, STORE_DEPOT } from "../../../../utils/constants/variables";
import { AddressCard } from "./addressCard";
import { getLangPrefix, getLivrasionEstimate, getShippingCity, getbasketLineItems, setStoreToBasket } from "../../../../helpers/helpers";
import { getMenu } from "../../../../layouts/main/header/redux/selectors";
import { MesAddressActionCreators } from "../../../monEspace/container/components/MesAddress/redux/actions";
import { OrderActionCreators } from "../../../basket/redux/actions";
import { StoreLocationActionCreators } from "../../../location/redux/actions";
import { LocationSearchActionCreators } from "../../../locationSearchInput/redux/actions";
import { getDeliveryMethod } from "../../../basket/redux/selectors";
import { AddressPopup } from "./addressPopup";
import { getUserInfo } from "../../../login/redux/selectors";
import i18next from "i18next";
import ChooseNearestStore from "../../../location/container/chooseNearestStore";
import { Checkbox } from "semantic-ui-react";

interface Porps {
  address?: any[];
  basket: any;
  basketProductsBySizes: any[];
  history: any;
  menuList: any[];
  billingAddress: any[];
  shippingAddress: any[];
  user: Record<string, any>;
  selectedShippingAddress: Record<string, any>;
  selectedBillingAddress: Record<string, any>;
  deliveryMethod: string;
  upsertSelectedAddressList: (...args: any) => void;
  dispatch: Dispatch;
}

const Address: React.FC<Porps> = ({
  address,
  basket,
  basketProductsBySizes,
  history,
  menuList,
  user,
  deliveryMethod,
  billingAddress,
  selectedShippingAddress,
  selectedBillingAddress,
  shippingAddress,
  upsertSelectedAddressList,
  dispatch
}) => {
  const [isAddressPopupVisible, setIsAddressPopupVisible] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<{ address: any, type: AddressTypes }>({ address: {}, type: AddressTypes.BILL });
  const [selectedPrice, setSelectedPrice] = useState<string>("1");

  const storeDelivery = basket && basket["store"] && basket["store"]["storeDelivery"];
  const selectedStore = basket && basket["store"] && basket["store"]["StoreInfo"];
  const label = storeDelivery === "STO" ? i18next.t('payment.17') : i18next.t('payment.16');

  const toAddresses = () => {
    const mesAddressMenu = menuList.find((menu) => menu.key === "mes_adresses");
    const LANGUAGE_PREFIX = getLangPrefix();
    const url = `${LANGUAGE_PREFIX}${mesAddressMenu.canonicalUrl}`;
    history.push(url);
  }

  const toggleAddressPopup = (id: any = "", type: AddressTypes, event) => {
    event.stopPropagation();

    let item: any = {};
    if (type === AddressTypes.BILL) {
      item = billingAddress.find(obj => obj.clientAddressId === id);
    } else {
      item = shippingAddress.find(obj => obj.clientAddressId === id);
    }

    if (!item) {
      item = { addressType: type };
    }

    setIsAddressPopupVisible(!isAddressPopupVisible);
    setSelectedAddress({ address: item, type: type })
  };

  const handleUpsertAddressItem = (data: any = {}) => {
    let addresses = data.addressType === AddressTypes.BILL ? billingAddress : shippingAddress;
    if (!data.clientAddressId && !data.webClientId) {
      const name = data.name ? data.name.split(" ") : "";
      const firstName = name[0];
      name.shift();
      const lastName = name.join(" ");
      const body = {
        "address1": data.address1 || "",
        "address2": data.address2 || "",
        "addressType": data.addressType || "",
        "city": data.city || "",
        "clientAddressId": 0,
        "firstName": firstName,
        "lastName": lastName,
        "country": data.country || "",
        "email": user && user.email || "",
        "isDefault": true,
        "name": data.name || "",
        "phone": data.phone || "",
        "zip": data.zip || ""
      };

      dispatch(MesAddressActionCreators.mesAddressPostStart(body))
    } else {
      const objIndex = addresses.findIndex(obj => obj.clientAddressId == data.clientAddressId);
      //REMOVE AFTER LASTNAME AND FIRSTNAME VALUE
      const seperated = data.name.split(" ");
      data.firstName = seperated[0];
      seperated.shift();
      data.lastName = seperated.join(" ");
      //end remove
      dispatch(MesAddressActionCreators.mesAddressPostStart(data))
      addresses[objIndex] = data;
    }
    setIsAddressPopupVisible(false);
  };

  const changeSelectedPrice = (num: string, method = "onlineDelivery") => {
    setSelectedPrice(num);
    const products = basketProductsBySizes.filter(product => product.webInventory === 0);

    if (selectedStore && !selectedStore.hasBasket && (products && products.length)) {
      // this.setState({ showInfoPopup: true });
      return;
    }
    if (num === "1") {

      setStoreToBasket({});
      dispatch(OrderActionCreators.getBasketStart());
      dispatch(StoreLocationActionCreators.resetStoreLocationStart());
      const shippingCity = getShippingCity();
      dispatch(LocationSearchActionCreators.clickAndCollectStoresStart({
        location: shippingCity,
        body: { basketLineItems: getbasketLineItems() },
        queryParams: { consignment: false, shipment: true }
      }))
      dispatch(StoreLocationActionCreators.selectedDeliveryMethodStart(method));
    }
    if (STORE_DELIVERY === "1" && deliveryMethod !== method) {
      dispatch(StoreLocationActionCreators.selectedDeliveryMethodStart(method));
    }
  };

  const getShipCardTitle = () => {
    return selectedPrice === "1"
      ? i18next.t('space.address.1')
      : storeDelivery === "DIS"
        ? i18next.t('product.basket.delivery.4')
        : i18next.t('product.basket.delivery.14');
  }

  const setBillingAddress = () => {
    const name = selectedShippingAddress.name ? selectedShippingAddress.name.split(" ") : "";
    const firstName = name[0];
    name.shift();
    const lastName = name.join(" ");
    const body = {
      "address1": selectedShippingAddress.address1 || "",
      "address2": selectedShippingAddress.address2 || "",
      "addressType": "BILL",
      "city": selectedShippingAddress.city || "",
      "firstName": firstName || "",
      "lastName": lastName || "",
      "country": selectedShippingAddress.country || "",
      "email": selectedShippingAddress.email || "",
      "isDefault": true,
      "name": selectedShippingAddress.name || "",
      "phone": selectedShippingAddress.phone || "",
      "zip": selectedShippingAddress.zip || ""
    };
    dispatch(MesAddressActionCreators.mesAddressPostStart(body))
  }

  return (
    <div className="card-container-wrapper">
      <div className="card-container">
        <div className="card-left-container direction-between">
          <h4 className="delivery-payment-title">{getShipCardTitle()}</h4>
          <div className={`delivery-payment-adress ${["STO", "DEP"].includes(storeDelivery) ? "store" : ""}`}>
            <div className="delivery-payment-adress-item-content dflex flex-wrap">
              {shippingAddress.map(item => item.isDefault && (
                <AddressCard
                  key={`shipping-${item.clientAddressId}`}
                  toAddresses={toAddresses}
                  selectedClientId={selectedShippingAddress.clientAddressId}
                  item={item}
                  type={AddressTypes.SHIP}
                  storeDelivery={storeDelivery}
                  onUpdate={upsertSelectedAddressList}
                />
              ))}
              {!shippingAddress.length &&
                <div className="delivery-payment-adress-item dflex flex-column justify-content-center">
                  <div className={"addAddress"}>
                    <h4 className="item-title">{i18next.t('product.basket.delivery.6')}</h4>
                    <div
                      className="delivery-payment-adress-item-plus dflex justify-content-center align-items-center"
                      onClick={(e) => toggleAddressPopup("", AddressTypes.SHIP, e)}>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="card-middle-container delivery-payment-adress direction-between">
          <h4 className="delivery-payment-title">{i18next.t("space.address.2")}</h4>
          <div className="delivery-payment-adress-item-content dflex flex-wrap">
            {billingAddress.map(item => item.isDefault && (
              <AddressCard
                key={`shipping-${item.clientAddressId}`}
                toAddresses={toAddresses}
                selectedClientId={selectedBillingAddress.clientAddressId}
                item={item}
                type={AddressTypes.BILL}
                storeDelivery={storeDelivery}
                onUpdate={upsertSelectedAddressList}
              />
            ))}

            {!billingAddress.length && <div className="delivery-payment-adress-item-wrapper">
              <div className="delivery-payment-adress-item dflex flex-column justify-content-center">
                <h4 className="item-title">{i18next.t('product.basket.delivery.6')}</h4>
                <div
                  className="delivery-payment-adress-item-plus dflex justify-content-center align-items-center"
                  onClick={(e) => toggleAddressPopup("", AddressTypes.BILL, e)}>
                </div>
              </div>
              {selectedShippingAddress && selectedShippingAddress.clientAddressId && <Checkbox
                onChange={setBillingAddress}
                className="mainCheckbox"
                label={
                  <label>{i18next.t('payment.18')}</label>
                }
              />}
            </div>
            }
          </div>
        </div>
        <div className="card-right-container direction-between">
          <h4 className="delivery-payment-title">{i18next.t('product.basket.delivery.1')}</h4>
          <div className="delivery-payment-price-content">
            {(STORE_DEPOT === "1" || STORE_DELIVERY === "1" || "") && (
              <ChooseNearestStore
                isReturn={false}
                classN={selectedPrice === "0" ? "active" : ""}
                selectedStore={selectedStore}
                storeDelivery={storeDelivery}
                from='deliveryPayment'
                handleClick={() => setSelectedPrice("0")}
                setActivePrice={(number) => setSelectedPrice(number)}
                changeSelectedPrice={changeSelectedPrice}
              />
            )}
          </div>
        </div>
      </div>
      <div className="cards-text-block">
        <div>{label}: <span className="livrasion-estimate-text">{getLivrasionEstimate(storeDelivery)}</span></div>
        {/* {orderMessage && <span className="error-text">{orderMessage}</span>} */}
        {/* {showMessage && <span className="error-text">{errorMessage}</span>} */}
      </div>
      {isAddressPopupVisible &&
        <AddressPopup
          handleToggleAddressPopup={toggleAddressPopup}
          handleUpsertAddressItem={handleUpsertAddressItem}
          item={selectedAddress.address}
        />
      }
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    menuList: getMenu(state),
    deliveryMethod: getDeliveryMethod(state),
    user: getUserInfo(state)
  }
};

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
}

export const AddressBlock = connect(mapStateToProps, mapDispatchToProps)(withRouter(Address as any));