import React, { Fragment, RefObject } from "react";
import { connect } from "react-redux";
import {
    getError,
    getMenu,
    getUser,
    getLoginPopup,
    getLoginError,
    getLanguage,
    getMenuLoading
} from "../../../redux/selectors";
import { getSearchingDataInput, loading } from "./../../../../../../modules/search/redux/selectors";
import { MenuActionCreators } from "../../../redux/actions";
import { getBasketData, replaceComma, getLang, getLangPrefix, emailIsValid } from "../../../../../../helpers/helpers";
import { bindActionCreators } from "redux";
import { IMenuState } from "../../../redux/reducers";
import SubMenu from "./submenu/container/submenu";
import { Tab } from 'semantic-ui-react'

import { DISCOUNT_CODE, HIDE_DISCOUNT, MENU_TYPE } from "../../../../../../utils/constants/variables";

import { Link, withRouter } from "react-router-dom";
import i18next from "i18next";

import search from './submenu/img-search/SEARCH.svg'
import close from '../../../../../../assets/img/close_black.svg';
import moncompte from './submenu/img-search/MON COMPTE.svg'
import magasin from './submenu/img-search/magasin.svg'
import painer from './submenu/img-search/PANIER.svg'
import { Icon } from 'semantic-ui-react';
import logo_1 from '../../../../../../assets/img/logo-1.png';

import BasketPopup from "../../../../../../modules/basketPopup/container/basketPopup";
import MenuMobile from "./mobile/menu";
import { isAuthenticated } from "../../../../../../modules/login/redux/selectors";

import './menu.scss'
import {
    getCurrency,
    getLocalStorageObject,
    getRouteByMenuKey,
} from "../../../../../../helpers/helpers";
import { getBasket } from "../../../../../../modules/addToCart/redux/selectors";
import { AddToCartActionCreators } from "../../../../../../modules/addToCart/redux/actions";
import { BASE_API_GATEWAY } from "./../../../../../../utils/constants/service";
import BlackWindow from "../../../../../../modules/common/blackWindow";
import Soumettre from "../../../../../../soumattre-popup/Soumetre";
import LinkFullUrl from "../../../../../../shared/linkFullUrl/linkFullUrl";
import { SearchingCreators } from "../../../../../../modules/search/redux/actions";
import { getWichList } from "../../../../../../modules/Wishlist/redux/selectors";

import heardIconTowishlist from "../../../../../../assets/img/heart-cropped.svg";

import './menu.scss';
import LogoLoader from "../../../../logoLoader";
import { TrackSearch } from "../../../../../../helpers/ga4Tracker";
import { OrderActionCreators } from "../../../../../../modules/basket/redux/actions";


interface Props extends IMenuState {
    handleMenuHover: any,
    handleMenuLeave: any,
    activeMenuItem: number,
    handleReceiveMenu: any,
    basket?: any,
    showLoginPopup?: boolean,
    loginFailed: string,
    language: string,
    isLoading: boolean,
    loading: boolean,
    wichList?: any
};

interface State {
    show?: boolean,
    menu?: any,
    error?: any,
    routeList?: any,
    showBasket?: any,
    loginModal?: boolean,
    scroll?: any,
    showMenuMobile?: boolean,
    items?: any,
    hideHeaderButtons?: boolean,
    basket?: any,
    user: any,
    searching: any,
    authenticated: boolean,
    showSearchBox?: boolean,
    criteria?: any,
    page?: any,
    size?: any,
    showSearchLine?: boolean,
    showSearchLineMobile?: boolean,
    showSoumetre?: boolean,
    showLoginPopup?: any,
    validEmail?: boolean,
    validPass?: boolean,
    loginFailed?: string,
    language?: string,
    isLoading?: boolean,
    LinkFullUrl?: any,
    isSearchLoading?: boolean,
    pending?: boolean,
    favCount?: number,
    wichList?: any,
    showSearchInput?: boolean
};

class Menu extends React.Component<Props & typeof MenuActionCreators & typeof AddToCartActionCreators, State> {
    state: State = {
        show: false,
        menu: {},
        error: {},
        routeList: [],
        showBasket: false,
        loginModal: false,
        scroll: '',
        showMenuMobile: false,
        items: {},
        hideHeaderButtons: false,
        basket: {},
        user: {
            email: '',
            password: ''
        },
        searching: {},
        authenticated: false,
        showSearchBox: false,
        criteria: '',
        page: 0,
        size: 4,
        showSearchLine: false,
        showSearchLineMobile: false,
        showSoumetre: false,
        showLoginPopup: false,
        validEmail: false,
        validPass: false,
        loginFailed: "",
        isLoading: false,
        isSearchLoading: false,
        favCount: 0,
        showSearchInput: false
    };

    searchInput: RefObject<HTMLInputElement> = React.createRef()

    componentDidMount() {

        this.props.menuStart();
        this.props.languageStart(getLang());

        const items = getLocalStorageObject("basket");
        this.setState({ items });
        this.props.getBasketStart();

        if (!this.props.authenticated && localStorage.getItem("token")) {
            this.props.tokenValidateStart(this.state.user);
        };
    };

    componentDidUpdate(prevProps, prevState) {
        const { searching, pending } = this.props;
        const { isSearchLoading } = this.state;
        const result = searching && searching.products && searching.products.content
        const prevSearching = prevProps.searching;
        const prevResult = prevSearching && prevSearching.products && prevSearching.products.content
        if (!prevProps.menu.length && this.props.menu.length) {
            this.setRouteList(this.props.menu);
        };

        if (!pending && pending !== isSearchLoading) {
            this.setState({ isSearchLoading: false });
        };

        if (this.props.menu[0] && prevProps.menu[0] && prevProps.menu[0].language !== this.props.menu[0].language) {
            this.setState({ routeList: [] }, () => { this.setRouteList(this.props.menu); })
        };
    };

    componentWillReceiveProps(nextProps) {
        if (!this.props.showLoginPopup && nextProps.showLoginPopup) {
            this.setState({ loginModal: nextProps.showLoginPopup })
        };

        if (nextProps.loginFailed !== this.props.loginFailed) {
            this.setState({ loginFailed: nextProps.loginFailed });
            nextProps.loginFailed === "SUCCESS" ? this.closeModal() : "";
        };

        if (localStorage.getItem("token") && nextProps.authenticated && this.props.authenticated !== nextProps.authenticated) {
            nextProps.userInfoStart()
        };
    };

    handleBasketToggle = (show) => {
        this.setState({ showBasket: show, hideHeaderButtons: !this.state.hideHeaderButtons });
    };

    handleLogin = (e) => {
        const { loginModal } = this.state;
        this.setState({ loginModal: !loginModal });
    };

    startSearching = (data, startLoading = false) => {
        const { searching } = this.props;
        const result = searching && searching.products && searching.products.content && searching.products.content.length;
        if (!result && startLoading) {
            this.setState({ isSearchLoading: this.state.criteria.length === 0 ? false : true });
        };
        if (this.state.criteria.length % 4 === 0) {
            this.props.searchingStart(data);
        };
    };

    handleShowSearchItems = (e) => {
        this.setState({ showSearchBox: true });
        this.setState(
            { criteria: e.target.value },
            () => this.startSearching(this.state)
        );
    };

    closeSearchingBox = (item: { brandId?: number, altBrandId?: number } = { brandId: 0, altBrandId: 0 }) => {
        if (item.brandId || item.altBrandId) {
            localStorage.selectedProductBrandId = item.altBrandId || item.brandId;
        };
        this.setState({ showSearchBox: false });
    };

    handleSearchItems = (e) => {
        this.setState({ showSearchLine: this.searchInput.current && this.searchInput.current.value ? true : false });

        this.setState(
            { criteria: e.target.value },
            () => this.startSearching(this.state, true)
        );
        TrackSearch(e.target.value)
    };

    clearSearchInput = () => {
        this.setState({ showSearchLine: false, criteria: '', showSearchBox: false });
    };

    clearMobileSearchInput = () => {
        this.setState({ showSearchLine: false, criteria: '' });
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13 && this.state.criteria) {
            this.props.history.push(`${getLangPrefix()}/search?criteria=${this.state.criteria}&page=0&size=12&sort=ranking,asc`);
            this.closeSearchingBox();
            this.setState({ showSearchLine: false, criteria: '' });
            this.searchInput.current && this.searchInput.current.blur();
            window.innerWidth < 920 && window.scrollTo({ top: 0, left: 0 });
        };
    };

    handleLoginSubmit = () => {
        this.props.loginStart(this.state.user);
    };

    handleLogout = () => {
        localStorage.removeItem(DISCOUNT_CODE);
        this.props.dispatch(OrderActionCreators.annullerDiscountStart());
        this.props.logoutStart(this.state.user);
        this.closeModal();
        this.props.history.push(getLangPrefix());
    };

    handleChangeEmail = (e) => {
        const email = e.target.value;
        const validEmail = emailIsValid(email);
        this.setState((prevState: State) => ({
            user: {
                ...prevState.user,
                email
            },
            validEmail
        }));

    };

    handleChangePassword = (e) => {
        const password = e.target.value;
        this.setState((prevState: State) => ({
            user: {
                ...prevState.user,
                password
            },
            validPass: !!password.length
        }));
    };

    showPass = () => {
        const {show} = this.state;
        this.setState({
            show: !show
        });
    };

    closeModal = () => {
        this.setState({ loginModal: false, showSearchBox: false, showSoumetre: false, showSearchLineMobile: false });
        this.props.emptySearchingStart();
    };

    closeModallogin = () => {
        this.props.clickPopUpStart();
        this.setState({ loginModal: false });
    };

    handleActive = (i, isMobile: boolean = false) => {
        this.props.handleMenuHover(i, isMobile);
        this.setState({ loginModal: false });
    };

    getRouteList = (menu: any = []) => {
        return menu.reduce((list, menuItem) => {
            if (menuItem.canonicalUrl) {
                list.push(menuItem);
            }
            if (menuItem.children && menuItem.children.length) {
                list = [...list, ...this.getRouteList(menuItem.children)];
            }
            return list;
        }, []);
    }

    setRouteList = (menu = []) => {
        const routeList = this.getRouteList(menu);

        this.setState({ routeList });
        this.props.handleReceiveMenu(routeList);
    };

    toggleMenu = () => {
        this.setState({ showMenuMobile: !this.state.showMenuMobile });
        this.handleActive(-1, true);
    };

    filterCategory = (value, key) => {
        return value.find((item) => item.searchFilter.toLowerCase() === key.toLowerCase())
            || { values: [{}] }
    };

    hendleShowSearchLine = () => {
        this.setState({ showSearchLine: !this.state.showSearchLine, criteria: "", showSearchLineMobile: !this.state.showSearchLineMobile })
    };

    handleShowSometre = () => {
        this.closeModal();
        this.setState({ showSoumetre: true })
    };

    hendleShowSearchLineMobile = () => {
        this.setState({
            showSearchLine: !this.state.showSearchLine,
            criteria: "",
            showSearchBox: true,
            showSearchLineMobile: !this.state.showSearchLineMobile
        });
    };

    handleMoveToShop = () => {
        const { menu, history } = this.props;
        const boutique = menu.find((menuItem) => menuItem.altUrl1 === 'boutique');
        history.push(`${getLangPrefix()}${boutique.canonicalUrl}`);
    };

    onClickHeardToWishlist = (e) => {
        const menu = this.props.menu || [];
        const isFindkeyWishlist = menu.find((item) => item.key === 'wishlist');

        if (isFindkeyWishlist) {
            this.props.history.push(`${getLangPrefix()}${isFindkeyWishlist.canonicalUrl}`);
        };
    };
    handleShowMobileSearch = () => {
        this.setState({ showSearchBox: true });
    }

    handleLoginOnKeyPress = (event) => {
        const { validEmail, validPass } = this.state;

        if (event.keyCode === 13 && validEmail && validPass) {
            this.handleLoginSubmit();
        }
    }

    render(): React.ReactNode {

        const showClass = this.state.show ? "show" : "";
        const {
            showBasket,
            loginModal,
            showMenuMobile,
            routeList,
            hideHeaderButtons,
            validPass,
            validEmail,
            loginFailed
        } = this.state;
        let {
            activeMenuItem,
            menu,
            styleOptions,
            basket,
            user,
            isLoading,
            searching,
            wichList
        } = this.props;
        const { totalQuantity } = getBasketData(basket);
        const { email, password } = this.state.user;
        const LANGUAGE_PREFIX = getLangPrefix();
        const searchProducts = searching &&
            searching.products &&
            searching.products.content
            && searching.products.content.filter(item => item.startingPrice > 0);

        if (menu.length) {
            menu = menu.sort((a, b) => parseInt(a.position) - parseInt(b.position));
        };

        const { detect } = require('detect-browser');
        const browser = detect();
        let browserCss = browser.name === "ie" ? "relative-IE" : "header-box";

        if (isLoading) {
            return <LogoLoader />
        };

        const panes = [
            {
                menuItem: this.state.criteria == ""
                    ? i18next.t('searching.2')
                    : i18next.t('catalogue.product.10'),
                render: () => <Tab.Pane attached={false}>
                    <div className="products hlafBox" style={searching && searching.categories ? {} : { width: '100%' }}>
                        <div className="ui text loader">Loading</div>
                        {
                            searchProducts && searchProducts.length ? searchProducts.map(item => (
                                <Link onClick={this.closeSearchingBox.bind(this, item)} className="item" key={item.productId} to={`${LANGUAGE_PREFIX}${item.menuCanonicalUrl}/${item.canonicalUrl}`} >
                                    <div className="imgBox"><img src={`${BASE_API_GATEWAY}${item.thumbnail}`} alt="img" /></div>
                                    <div className="infoBox">
                                        <p className="title">{item.name}</p>
                                        <p className="category-name">{this.filterCategory(item.attributes, "Collection").values[0].value}</p>
                                        <p className="product-price">
                                            <span>{i18next.t('catalogue.product.3')}</span>
                                            {
                                                HIDE_DISCOUNT === "0" && item.listPriceAfterDiscount ?
                                                    <span className='old-price'> {replaceComma(item.startingPrice)}</span> : ""
                                            }
                                            <span className="new-price">{replaceComma(item.listPriceAfterDiscount ? item.listPriceAfterDiscount : item.startingPrice)} {getCurrency(item.currency)}</span>

                                        </p>
                                    </div>
                                </Link>
                            )) : i18next.t('searching.1')}</div>
                </Tab.Pane>,
            },
            {
                menuItem: this.state.criteria != "" ? i18next.t('catalogue.product.11') : '',
                render: () => <Tab.Pane attached={false}>
                    <div className={`hlafBox ${searching && searching.categories ? '' : 'd-none'}`}>
                        {
                            searching && searching.categories ? searching.categories.map((item, index) => (
                                index <= 5 ?
                                    <Link onClick={this.closeSearchingBox.bind(this, { brandId: 0 })} className="item" key={index} to={`${LANGUAGE_PREFIX}${item && item.canonicalUrlLevel2}`} >
                                        <div className="infoBox">
                                            <p>{item.labelLevel0 + "/" + item.labelLevel1 + "/" + item.labelLevel2 + " (" + item.totalMatched + ")"}</p>
                                            <p className='discountDetail'>{item.discountDetail}</p>
                                        </div>
                                    </Link> : ""
                            )) : this.state.criteria != "" ? i18next.t('searching.1') : ""
                        }
                    </div>
                </Tab.Pane>,
            },
        ]


        return (
            <Fragment>
                <div className={`${browserCss}`}>
                    <div className="header-logo-wrapper">
                        <div className="header-logo">
                            <div className="d-center menu-btn-content">
                                <div className="menu-btn" onClick={this.toggleMenu}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <img src={this.state.showSearchLineMobile ? close : search} className="search-icon mobile" onClick={this.hendleShowSearchLineMobile} alt="PHOTO" />
                            </div>

                            <Link to={`${getLangPrefix()}/`}>
                                <img src={logo_1} alt="logo" className="logo" />
                            </Link>

                            {this.state.showSoumetre &&
                                <Soumettre
                                    history={this.props.history}
                                    email=''
                                    legacyUser={false}
                                    handleClose={this.closeModal}
                                />
                            }

                            {!hideHeaderButtons &&
                                <div className='search-container'>
                                    <label htmlFor="na"></label>
                                    <input
                                        ref={this.searchInput}
                                        className={`${this.state.showSearchLine ? "showInput" : ""}`}
                                        type="text"
                                        value={this.state.criteria}
                                        placeholder={i18next.t('common.address_search')}
                                        onClick={this.handleShowSearchItems}
                                        onChange={this.handleSearchItems}
                                        onKeyDown={this.handleKeyDown}
                                    />
                                    <div
                                        className="search-content-input-btn name-menu-item header-search-icon"
                                        onClick={() => this.clearSearchInput()}
                                    >
                                        <img src={this.state.showSearchLine ? close : search} className="search-icon" alt="PHOTO" />
                                    </div>
                                </div>
                            }

                            {!hideHeaderButtons &&
                                <div className="d-between header-logo-btns align-items-center">
                                    {loginModal &&
                                        <BlackWindow type="page" handleClick={this.closeModallogin} />
                                    }
                                    <div className="modal-wrapper name-menu-item">
                                        <div className='userIcon nav-icon'>
                                            <img
                                                src={moncompte}
                                                alt="PHOTO"
                                                onClick={this.handleLogin}
                                            />
                                        </div>
                                        <span onClick={this.handleLogin}>{i18next.t('signIn.6')}</span>
                                        {loginModal ?
                                            <div className="modal open">
                                                {
                                                    this.props.authenticated ?
                                                        <div className="modal-inner">
                                                            {user && user.email &&
                                                                <div className="user-info">
                                                                    <div className='user-modal-close-btn' onClick={this.closeModal}>&#10005;</div>
                                                                    <p>{i18next.t('signIn.2')}</p>
                                                                    <p className="email">{user.email}</p>
                                                                </div>
                                                            }
                                                            <Link className="profile"
                                                                to={getRouteByMenuKey(routeList, "mon_compte").route}
                                                                onClick={this.closeModal} >
                                                                <button
                                                                    className="modal-btn h-50 hoverButtonStyle">{i18next.t('signIn.profileButton')}</button>
                                                            </Link>
                                                            <button className="modal-btn h-50 hoverButtonStyle" onClick={this.handleLogout}>{i18next.t('signIn.logoutButton')}</button>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="modal-inner">
                                                                <h5 className="title">{i18next.t('signIn.signInTitle')}</h5>
                                                                <div className="modal-input">
                                                                    <label>{i18next.t('signIn.emailAddress')}</label>
                                                                    <input type="text"
                                                                        placeholder={i18next.t('signIn.4')}
                                                                        name="email"
                                                                        value={email}
                                                                        onChange={this.handleChangeEmail}
                                                                        onKeyDown={this.handleLoginOnKeyPress}
                                                                    />
                                                                </div>
                                                                <div className="modal-input pass">
                                                                    <label>{i18next.t('signIn.motDePasse')}</label>
                                                                    <input type={this.state.show ? "text" : "password"}
                                                                        placeholder={i18next.t('signIn.5')}
                                                                        name="password"
                                                                        value={password}
                                                                        onChange={this.handleChangePassword}
                                                                        onKeyDown={this.handleLoginOnKeyPress}
                                                                    />
                                                                    <span className={`icon-pass ${showClass}`}
                                                                        onClick={this.showPass}>
                                                                        <Icon name={this.state.show ? "eye" : "eye slash"}></Icon>
                                                                    </span>
                                                                </div>

                                                                <div onClick={this.handleShowSometre} className="text-colored">
                                                                    {i18next.t('signIn.motDePasseOublie')}
                                                                </div>


                                                                <button
                                                                    className={`modal-btn h-50 hoverButtonStyle ${!(validEmail && validPass) ? "disabled" : ""}`}
                                                                    disabled={!(validEmail && validPass)}
                                                                    onClick={this.handleLoginSubmit}
                                                                >
                                                                    {i18next.t('signIn.signIpTitle')}
                                                                </button>

                                                                {
                                                                    loginFailed === "FAILED" ? <h5 className="login-failed-message">{i18next.t('signIn.1')}</h5> : ""
                                                                }

                                                            </div>
                                                            <div className="modal-footer">
                                                                <h5 className="title">{i18next.t('signIn.nouveauClient')}</h5>
                                                                <Link to={getRouteByMenuKey(routeList, "creation_de_compte").route}
                                                                    onClick={this.closeModal}>
                                                                    <button
                                                                        className="modal-btn h-50 hoverButtonStyle">{i18next.t('signIn.creerUnCompte')}</button>
                                                                </Link>
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                            : ""
                                        }
                                    </div>
                                    <div
                                        className="name-menu-item buticContainer"
                                        onClick={this.handleMoveToShop}
                                    >
                                        <div className='buticIcon nav-icon'>
                                            <img src={magasin} alt="PHOTO" />
                                        </div>
                                        <span>{i18next.t('footer.boutiques.2')}</span>
                                    </div>
                                    <div className="name-menu-item to-wichlist-heard-icon" onClick={this.onClickHeardToWishlist}>
                                        <div className='favIcon nav-icon'>
                                            {wichList.length ? <div className="fav-bag-count">{wichList.length}</div> : ""}
                                            <img
                                                src={heardIconTowishlist}
                                            />
                                        </div>
                                        <span>{i18next.t('product.basket.41')}</span>
                                    </div>
                                    <div
                                        className="name-menu-item "
                                        onClick={() => this.handleBasketToggle(true)}
                                    >
                                        <div className='shopIcon nav-icon'>
                                            {Object.keys(basket).length == 0 ? null : <div className="shopping-bag-count" > {totalQuantity} </div>}
                                            <img src={painer} alt="PHOTO" />
                                        </div>
                                        <span>{i18next.t('product.basket.37')}</span>
                                    </div>
                                    <div
                                        className="name-menu-item last search-modal-btn-mobile"
                                        onClick={this.handleShowMobileSearch}
                                    >
                                        <img src={search} className="search-icon" alt="PHOTO" />
                                    </div>
                                </div>
                            }
                        </div>

                        {this.state.showSearchBox ? (
                            <div className="search-result-container">
                                <BlackWindow type="SearchingBox" handleClick={this.closeModal} />
                                <div className="searchContent" >
                                    <img src={close} className="search-modal-close-btn" alt="PHOTO" onClick={() => this.clearSearchInput()} />
                                    <div className='mobile-search'>
                                        {!hideHeaderButtons &&
                                            <div className='search-container search-container-mobile'>
                                                <label htmlFor="na"></label>
                                                <input
                                                    ref={this.searchInput}
                                                    className={`${this.state.showSearchLine ? "showInput" : ""}`}
                                                    type="text"
                                                    value={this.state.criteria}
                                                    placeholder={i18next.t('common.address_search')}
                                                    onClick={this.handleShowSearchItems}
                                                    onChange={this.handleSearchItems}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                                <div
                                                    className="search-content-input-btn name-menu-item nav-icon modal-icon"
                                                    onClick={() => this.clearMobileSearchInput()}
                                                >
                                                    <img src={this.state.showSearchLine ? close : search} className="search-icon" alt="PHOTO" />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="searchInfoContent">
                                        <Tab menu={{ text: true }} panes={panes} />
                                        {this.state.criteria != "" && searching && searching.products ?
                                            <Link to={`${LANGUAGE_PREFIX}/search?criteria=${this.state.criteria}&page=0&size=12&sort=ranking,asc`}
                                                onClick={this.closeSearchingBox.bind(this, { brandId: 0 })} className="totalText">
                                                {/* {i18next.t('searching.3')}  &nbsp;({this.props.searching.products.totalElements}) */}
                                                {i18next.t('searching.3')}  &nbsp;
                                            </Link>
                                            :
                                            ""
                                        }
                                        {this.state.isSearchLoading && (
                                            <div className="ui segment">
                                                <div className="ui active inverted dimmer">
                                                    <div className="ui text loader">Loading</div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>) : ""
                        }
                    </div>

                    <nav className={`header-navbar ${this.state.scroll} ${styleOptions ? "style-options" : ""} `}
                        onMouseLeave={activeMenuItem !== -1 ? this.props.handleMenuLeave : undefined}
                        style={styleOptions ? { 'top': 0, borderTop: '1px #e7e7e7 solid' } : {}}
                    >
                        <div className="home-container">
                            <ul className="header-navbar-menu d-center">
                                {menu.length ?
                                    menu.map((item, i) => (
                                        item.menuType === MENU_TYPE &&
                                        <li key={item.menuId} className={`${i === activeMenuItem ? 'active' : ''} ${item.colored ? "colored" : ""}`}
                                            onMouseOver={this.handleActive.bind(this, i, false)}
                                        >{item.children ? item.label : ""}{!item.children ? <LinkFullUrl className="magazin-menu-navbar" to={`${LANGUAGE_PREFIX}${item.canonicalUrl}`}>{item.label}</LinkFullUrl> : ""}</li>
                                    )) : ""
                                }
                            </ul>
                        </div>
                        <SubMenu menuItem={menu[activeMenuItem]} handleClick={this.props.handleMenuLeave} />

                        {
                            showBasket &&
                            <BasketPopup handleBasketToggle={this.handleBasketToggle} />
                        }
                        {
                            showMenuMobile &&
                            <MenuMobile menu={menu} activeMenuItem={activeMenuItem} handleActive={this.handleActive}
                                handleToggleMenu={this.toggleMenu} />
                        }
                    </nav>

                </div>
            </Fragment>
        );
    };
};

const mapStateToProps = (state: any): State => ({
    menu: getMenu(state),
    error: getError(state),
    basket: getBasket(state),
    user: getUser(state),
    authenticated: isAuthenticated(state),
    searching: getSearchingDataInput(state),
    showLoginPopup: getLoginPopup(state),
    loginFailed: getLoginError(state),
    language: getLanguage(state),
    isLoading: getMenuLoading(state),
    pending: loading(state),
    wichList: getWichList(state)
});

const mapDispatchToProps = (dispatch): typeof MenuActionCreators => {
    return bindActionCreators({
        ...MenuActionCreators,
        ...AddToCartActionCreators,
        emptySearchingStart: SearchingCreators.emptySearchingStart,
        dispatch
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
