import React from "react";
import {connect} from "react-redux";
import i18next from "i18next";
import "./magasin.scss";

import MapComponent from "../../location/container/map";
import {
    formatOpeningHours,
    getCurrentMenuItem,
    getOpeningHours,
    getRouteByMenuKey
} from "../../../helpers/helpers";
import LocationSearchInput from "../../locationSearchInput/container/locationSearchInput";
import {getLocationSearch, getLocationSearchLoading} from "../../locationSearchInput/redux/selectors";
import {getUserStore} from "../../location/redux/selectors";
import {StoreLocationActionCreators} from "../../location/redux/actions";
import {bindActionCreators} from "redux";
import CurrentPage from "../../../layouts/common/currentPage";
import {DEFAULT_LOCATION,  PERSONALIZATION} from "../../../utils/constants/variables";
import {getRecommendedProductsData} from "../../home/redux/selectors";
import RecommendedProducts from "../../home/container/components/recommendedProducts";
import {Link} from "react-router-dom";
import {getMenu} from "../../monEspace/redux/selectors";

interface Props {
    handleShow?: any,
    locationSearchData: any,
    userStore: any,
    storeLocationStart?: any,
    menuItem?: any,
    recommendedProducts: any,
    menuList:any,
    loading: boolean
}

interface State {
    storeId: any
}

class Magasin extends React.Component<Props  & typeof StoreLocationActionCreators , State> {

    constructor(props) {
        super(props);
        this.state = {
            storeId: null
        };

    }

    private refsArray: any = [];
    componentDidMount(): void {
        getCurrentMenuItem(this.props.menuList, true) && this.props.locationSearchStart(DEFAULT_LOCATION);
        this.props.recommendedProductsStart();
    }

    setLocation = (Id) => {
        this.setState({
            storeId: Id
        });
    };

    render() {
        const { locationSearchData, menuItem, recommendedProducts, menuList, loading } = this.props;
        return (
            <section className="magasin">
                {menuItem && Object.keys(menuItem).length ?
                    <CurrentPage selectedItem={menuItem} />
                    : ""
                }
                <div className="container">
                    <div className="magasin--header">
                        <h3 className="magasin--header--title">{i18next.t('locator.6')}</h3>
                        <div className="magasin--header--input">
                            <LocationSearchInput isFooter={false} showButton={true}/>
                        </div>
                    </div>
                    <div className="magasin--body">
                        <div className="magasin--body--map">
                            <MapComponent
                                locationSearch={locationSearchData}
                                locationStoreProps={this.props.storeLocationStart}
                                setLocation={this.setLocation}
                                loading={loading}
                            />
                        </div>
                        <div className="magasin--body--info">
                            <div className="location-wrapper-item infoArea">
                                <div className="location-addres-content">

                                    {
                                        locationSearchData.length === 0 ?
                                            <div className="location-adres-item">{i18next.t("locator.9")} </div> : ""
                                    }

                                    {
                                        locationSearchData && locationSearchData.length ? locationSearchData.map((item) => {
                                            return (
                                                <div className="location-adres-item" ref={ref => {
                                                    this.refsArray[item.storeId] = ref;
                                                }} key={item.storeId}>

                                                    <h4>{item.name}</h4>
                                                    <div className="location-adres-text-content">
                                                        <div>
                                                            <p>{item.address}</p>
                                                            <p>{item.zip}</p>
                                                            <p>{item.city}</p>
                                                            <p >Tél: {item.phone}</p>
                                                        </div>
                                                        {
                                                            item.personalShopper && getRouteByMenuKey(menuList, "shopper").route?
                                                                <Link  to={
                                                                    {
                                                                        pathname:`${getRouteByMenuKey(menuList, "shopper").route}`,
                                                                        state:{
                                                                            store: item,
                                                                        }
                                                                    }}
                                                                       className={`main-btn magasin--body--info--btn hoverButtonStyle`}>
                                                                    {i18next.t("magasin.1")}
                                                                </Link> :""
                                                        }
                                                        <div>
                                                            {
                                                                getOpeningHours(item.openingHours) &&
                                                                getOpeningHours(item.openingHours).length ?
                                                                    getOpeningHours(item.openingHours).map((hours, i) => {
                                                                        return (
                                                                            <div className='TimeBox' key={i}>
                                                                                <div>{` ${i18next.t(`weekDays.${i}`)}:`}</div>
                                                                                <div>{`${formatOpeningHours(hours)}`}</div>
                                                                            </div>
                                                                        )
                                                                    }) : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    PERSONALIZATION === "1" && recommendedProducts ?
                        <RecommendedProducts recommendedProducts={recommendedProducts} sliderType="home"/>
                        : null
                }
            </section>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        locationSearchData: getLocationSearch(state),
        userStore: getUserStore(state),
        recommendedProducts: getRecommendedProductsData(state),
        menuList: getMenu(state),
        loading: getLocationSearchLoading(state)
    }
};
const mapDispatchToProps = (dispatch): typeof StoreLocationActionCreators => {
    return bindActionCreators(StoreLocationActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Magasin);
