import React from "react";
import i18next from "i18next";
import Location from "../../../../../modules/location/container/location";
import LocationSearchInput from "../../../../../modules/locationSearchInput/container/locationSearchInput";
import back from "../../../../../assets/img/grey-background.jpg"
import { LazyImage } from "react-lazy-images";
import { getLanguage, getMenu, getMenuItem } from "../../../header/redux/selectors";
import { MenuActionCreators } from "../../../header/redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

interface State {
    showLocation: boolean
}

class Search extends React.Component {
    state: State = {
        showLocation: false
    };
    handleShowLocation = () => {
        this.setState({ showLocation: !this.state.showLocation })
    };

    render() {

        const { showLocation } = this.state;

        return (
            <section className="footerSearchBox">
                <div className="search-section search">

                    <LazyImage
                        src={back && back}
                        alt="product-image"
                        placeholder={({ imageProps, ref }) => (
                            <img
                                ref={ref}
                                src={back}
                                alt={imageProps.alt}

                            />
                        )}
                        actual={({ imageProps }) => (
                            <img {...imageProps} />
                        )}
                    />

                    <div className="home-container">
                        <div className="search-content">
                            <h4>{i18next.t('footer.boutiques.1')}</h4>
                            <div className="search-content-input d-center">
                                <LocationSearchInput onClick={this.handleShowLocation} />
                            </div>
                        </div>
                    </div>
                </div>
                {showLocation &&
                    <Location handleShow={this.handleShowLocation} />
                }
            </section>
        );
    }
}


const mapStateToProps = (state) => ({
    menu: getMenu(state),
    language: getLanguage(state),
    menuItem: getMenuItem(state),

});

const mapDispatchToProps = (dispatch): typeof MenuActionCreators => {
    return bindActionCreators({ ...MenuActionCreators }, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(Search);
