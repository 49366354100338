import {createActions} from 'reduxsauce';

export const {Types: PaymentTypes, Creators: PaymentActionCreators} = createActions ({
    amountStart: ['orderId', 'email'],
    amountSuccess: ['data'],
    amountFailure: ['error'],
    updateOrderStart: ["data"],
    selectedDeliveryMethodStart: ["method"],
    userPaymentPaypalStart: ['body', 'callback'],
    createClientDiscountStart: ['body'],
    createClientDiscountSuccess: ['data'],
    createClientDiscountFailure: ['error']
});
