import React, { useEffect, useMemo, useRef, useState } from "react"
import i18next from "i18next";
import PaypalBtn from 'react-paypal-checkout';
import ResponseMessage from "../../../responseMessage/container";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Button, Checkbox, Icon } from 'semantic-ui-react'
import { Link, RouteComponentProps } from "react-router-dom"
import { withRouter } from "react-router-dom";
import { createWebOrderLineItems, dataLayerPush, decryptCryptr, getConvertedItemDataForGA4, getLangPrefix, getMenuItemByMenuIdElem, getShippingCity, getbasketLineItems, isProductsInBarcode, tooFixed } from "../../../../helpers/helpers";
import { getBrandDetail, getMenu, getUser } from "../../../../layouts/main/header/redux/selectors";
import { getAmount, getEncriptAmount, getLoading } from "../../../payment/redux/selectors";
import {
  BRAND_ID, COUNTRY, DEFAULT_CURRENCY,
  DELIVERY_PRICE, FOREIGN_DELIVERY, LANGUAGE, ORDER_STATUS, PAYPAL_CLIENT_ID, PAYPAL_ENV, PRIVATE_DISCOUNT, STORAGE_BASKET_INDEX, STORE_DELIVERY, STORE_DEPOT, initialMessage
} from "../../../../utils/constants/variables";
import { getCreateOrder, getDeliveryMethod, getDiscount, getPromotionDiscount } from "../../../basket/redux/selectors";
import { LocationSearchActionCreators } from "../../../locationSearchInput/redux/actions";
import { OrderActionCreators } from "../../../basket/redux/actions";
import { PaymentActionCreators } from "../../../payment/redux/actions";
import { TrackPurchase } from "../../../../helpers/ga4Tracker";
import ConfirmPopup from "../../../common/confirmPopup/confirmPopup";
interface Props {
  address: any[];
  menuList: any[];
  loading: boolean;
  basketStore: any;
  basketData: any;
  brandDetail: any;
  amount: any;
  encriptAmount: string;
  deliveryMethod: string;
  basket: any;
  basketProductsBySizes: any[];
  shippingAddress: any[];
  billingAddress: any[];
  selectedShippingAddress: Record<string, any>;
  selectedBillingAddress: Record<string, any>;
  discount: any;
  promotionDiscount: any;
  order: any;
  user: any;
  dispatch: (...args) => void;
}

export const Payment: React.FC<Props & RouteComponentProps> = ({
  address,
  menuList,
  basketData,
  basketStore,
  discount,
  promotionDiscount,
  brandDetail,
  basket,
  deliveryMethod,
  shippingAddress,
  billingAddress,
  selectedShippingAddress,
  selectedBillingAddress,
  basketProductsBySizes,
  order,
  user,
  amount,
  encriptAmount,
  loading,
  history,
  dispatch
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [messageOptions, setMessageOptions] = useState<Record<string, any>>({ ...initialMessage });
  const [isPayPalLoading, setIsPayPalLoading] = useState<boolean>(false);
  const [showInfoPopUp, setShowInfoPopup] = useState<boolean>(false);
  const payRef = useRef<HTMLFormElement>(null);
  const prevStore = useRef<Record<string, any>>({});
  const isPaymentCheckedAllowed = true;
  const createdOrder = order[0] || {};
  const createdOrderId = createdOrder.orderId || null;
  const storeDelivery = basketStore.storeDelivery;
  const selectedStore = basketStore.StoreInfo;
  const isShippingDisable = storeDelivery !== "STO" ? !selectedShippingAddress.clientAddressId : false
  const disabled = !isChecked || !createdOrderId || !selectedBillingAddress.clientAddressId || isShippingDisable;
  const currentAmount = amount && amount.eptResponse && amount.eptResponse.amount;

  const cgvMenu = useMemo(() => {
    return menuList.find(item => item.altUrl1 === "cgv") || {};
  }, [menuList])

  useEffect(() => {
    if (basketData.totalQuantity && isChecked) setIsChecked(false);
    if (basketData.totalQuantity === 0) dispatch(OrderActionCreators.annullerDiscountStart());
  }, [basketData.totalQuantity])

  useEffect(() => {
    if (prevStore.current.discount === null && discount && isChecked) {
      setIsChecked(false);
    }

    if (prevStore.current.discount && discount === null && isChecked) {
      setIsChecked(false);
    }
    if (prevStore.current.discount && discount && discount.value && (prevStore.current.discount.couponType !== discount.couponType 
      || prevStore.current.discount.value !== discount.value) && isChecked) {
      setIsChecked(false);
    }

    return () => {
      prevStore.current.discount = discount;
    }
  }, [discount])

  const hendleChangeCeckBoxCheked = (_, input) => {
    if (input.checked) handleCreateOrder();

    setIsChecked(input.checked);
  }

  const showMessage = (status: string, text: string) => {
    setMessageOptions({
      status: status,
      text: text,
      isVisible: true
    });

    setTimeout(() => {
      setMessageOptions({ ...initialMessage });
    }, 7000);
  }

  const handleSubmit = () => {
    if (payRef.current) {
      payRef.current.submit();
    }
  }
  const isAtLeastOneNotPartOfCoupon = isProductsInBarcode()
  const createOrder = () => {
    const { webOrderLineItems, key } = createWebOrderLineItems(basket, basketData);
    const privateDiscountCode = localStorage.getItem(PRIVATE_DISCOUNT);

    if (storeDelivery !== "STO" && !shippingAddress.length) {
      setIsChecked(false);
      return showMessage('failed', i18next.t('product.basket.delivery.4'));
    }
    if (
      FOREIGN_DELIVERY === "0" &&
      STORE_DELIVERY === "1" &&
      storeDelivery !== "STO" &&
      selectedShippingAddress.countryCode &&
      selectedShippingAddress.countryCode.toUpperCase() !== LANGUAGE.toUpperCase()
    ) {
      setIsChecked(false);
      return showMessage('failed', i18next.t('payment.12'));
    }

    // if (price < DELIVERY_PRICE && webOrderLineItems.length) {
    //   if (storeDelivery !== 'DEP' && storeDelivery !== 'STO') {
    //     const itemType = selectedPrice === "3" ? "FREIGHT_EXP" : "FREIGHT_COST";
    //     webOrderLineItems.push({
    //       orderLineId: key,
    //       currency: DEFAULT_CURRENCY,
    //       itemType
    //     })
    //   }
    // }

    if (basketData.price < DELIVERY_PRICE && webOrderLineItems.length) {
      webOrderLineItems.push({
        orderLineId: key,
        currency: DEFAULT_CURRENCY,
        itemType: "FREIGHT_COST"
      })
    }

    const discountCode = privateDiscountCode
      ? privateDiscountCode : discount && discount.discountCode
        ? discount.discountCode : ""

    const shippingAddressId = deliveryMethod === "storeDelivery" && storeDelivery === "STO"
      ? (shippingAddress.find(item => item.isDefault === true) || {}).clientAddressId
      : selectedShippingAddress.clientAddressId
        ? selectedShippingAddress.clientAddressId
        : (shippingAddress.find(item => item.isDefault === true) || {}).clientAddressId

    const billingAddressId = selectedBillingAddress
      ? selectedBillingAddress.clientAddressId
      : (billingAddress.find(item => item.isDefault === true) || {}).clientAddressId

    setMessageOptions({ ...initialMessage });

    if (webOrderLineItems.length && billingAddressId) {
      const storeCode = selectedStore.storeCode || "";
      const body = {
        billingAddressId,
        brandId: BRAND_ID,
        ...(!isAtLeastOneNotPartOfCoupon && { discountCode }),
        orderId: null,
        country: COUNTRY,
        orderStatus: ORDER_STATUS.CHE,
        shippingAddressId,
        storeCode,
        webOrderLineItems,
        browserId: decryptCryptr("browserId"),
        ...(promotionDiscount && { promotionRuleId: promotionDiscount.promotionRuleId })
      };

      dispatch(OrderActionCreators.createOrderStart(body, "create-order"));
    } else if (!billingAddressId) {
      setIsChecked(false);
      return showMessage('failed', i18next.t("addToCart.6"));
    } else {
      setIsChecked(false);
      return showMessage('failed', i18next.t("addToCart.5"));
    }
  }

  const handleCreateOrder = (callDirectly = false) => {
    if (STORE_DELIVERY === "0") return createOrder()
    if (callDirectly) return createOrder();

    basketProductsBySizes.length && dispatch(LocationSearchActionCreators.clickAndCollectStoresStart({
      location: getShippingCity(),
      body: {
        basketLineItems: basketProductsBySizes.map((product) => ({ productId: product.productId, quantity: product.quantity }))
      },
      queryParams: storeDelivery === 'DIS'
        ? { shipment: true }
        : { consignment: true }
    }, (res) => {
      if (!res || !res.length) {
        setIsChecked(false)
        return setShowInfoPopup(true)
      }
      try { createOrder() } catch (e) { console.log(e) }
    }))
  };

  const onPaypalLogGA4 = (paypalInfo) => {
    try {
      const orderStatus = storeDelivery === 'DIS' && basketStore.storeType === 'E' ? 'PAI' : 'STO';
      if (orderStatus === 'PAI' || orderStatus === 'STO' || orderStatus === 'DIS') {
        const { response: { transactionId } } = paypalInfo;
        if (transactionId) {
          const { price } = basketData;
          const items = [] as any
          const vDiscount = discount;
          const { value = 0 } = vDiscount
          const dValue = tooFixed(value > 0 ? price - (price / value) : price, 2)
          Object.keys(basket).map((item: any) => {
            if (item !== 'store') {
              const basketMenuItem = getMenuItemByMenuIdElem(menuList, basket[item].menuId)
              basket[item].sizes && basket[item].sizes.length && basket[item].sizes.map((sizeElement, i) => {
                const dataT = getConvertedItemDataForGA4(
                  basketMenuItem,
                  basket[item],
                  sizeElement.quantity && sizeElement.quantity > 0 ? sizeElement.quantity : 1,
                  sizeElement
                );
                dataT.location_id = 'paypal'
                items.push(dataT)
              })
            }
          })
          TrackPurchase(items, dValue)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="peymant-content-item peymant-content-item-code mb_0">
      <h4 className={'text-uppercase'}>{i18next.t('payment.1')}</h4>
      <Checkbox
        onChange={hendleChangeCeckBoxCheked}
        className="mainCheckbox"
        disabled={!isPaymentCheckedAllowed}
        checked={isChecked}
        label={
          <label>{i18next.t('payment.2')}
            <Link className={'main-link ml_15'}
              to={getLangPrefix() + cgvMenu.canonicalUrl} target="_blank" >
              {cgvMenu.label}
            </Link>
          </label>
        }
      />
      {messageOptions.isVisible && (
        <ResponseMessage
          status={messageOptions.status}
          errorText={messageOptions.text}
          successText={messageOptions.text}
        />
      )}
      <div>
        <Button loading={loading}
          className={`paymentBtn hoverButtonStyle button-style Dflex align-items-center justify-content-center`}
          disabled={disabled || !basketProductsBySizes.length}
          onClick={handleSubmit}>
          <Icon className={"credit card outline icon iconWhited"} /> {i18next.t('payment.15')}
        </Button>
        {loading || isPayPalLoading ? (
          <Button basic loading className="paymentBtn">
            Loading
          </Button>
        ) : (
          <Button
            basic
            className={`paymentBtn  ${disabled ? "disabled" : ""}`}
            disabled={disabled || !Boolean(basket && Object.keys(basket).find(item => item !== 'store'))}
          >
            <PaypalBtn
              env={PAYPAL_ENV}
              client={{ [PAYPAL_ENV]: PAYPAL_CLIENT_ID }}
              currency={DEFAULT_CURRENCY}
              total={currentAmount ? Number(currentAmount) : 0}
              style={{
                shape: 'rect',
                size: 'responsive',
                tagline: false,
                color: 'gold',
                layout: 'horizontal',
                label: 'paypal',
              }}
              onSuccess={(payment) => {
                dataLayerPush(createdOrderId, user && user.email)
                const body = {
                  userEmail: payment.email,
                  transactionDate: new Date().toISOString(),
                  orderId: createdOrderId,
                  transactionId: payment.paymentID,
                  reference: payment.paymentToken,
                  amount: encriptAmount,
                  paymentAmount: currentAmount,
                  currency: DEFAULT_CURRENCY,
                  status: "PAYMENT_CONFIRMED",
                  ipAddress: "",
                  paymentMethod: "Paypal"

                }

                dispatch(PaymentActionCreators.userPaymentPaypalStart(body, (paymentInfo) => {
                  const status = paymentInfo && paymentInfo.status === "SUCCESS" ? "accepted" : "failed";

                  if (status === "accepted") {
                    history.push({
                      pathname: `${getLangPrefix()}/accepted-paypal`,
                      state: {
                        payPalData: {
                          ...payment,
                          createdOrderId,
                          reference: payment.paymentToken,
                          paypalStatus: status
                        },
                        BaskstStore: storeDelivery ? basket["store"] : ""
                      }
                    });
                    onPaypalLogGA4(paymentInfo);
                  } else {
                    history.push({
                      pathname: `${getLangPrefix()}/failed-paypal`,
                      state: {
                        payPalData: {
                          paypalStatus: "failed",
                          createdOrderId
                        }
                      }
                    });
                  }
                }))
                setIsPayPalLoading(true);
              }}
              onError={() => {
                setIsPayPalLoading(true);
                history.push({
                  pathname: `${getLangPrefix()}/failed-paypal`,
                  state: {
                    payPalData: {
                      paypalStatus: "failed",
                      createdOrderId
                    }
                  }
                });
              }}

              onCancel={() => {
                setIsPayPalLoading(false);
              }} />

          </Button>
        )}
        {amount && amount.eptResponse &&
          <form
            name="MoneticoFormulaire"
            target="_self"
            action={`${amount.eptResponse.url}/${amount.eptResponse.urlPayment}`}
            ref={payRef}
          >
            <input type="hidden" name="version" value={amount.eptResponse.version} />
            <input type="hidden" name="TPE" value={amount.eptResponse.eptNumber} />
            <input type="hidden" name="contexte_commande" value={amount.eptResponse.contexteCommande} />
            <input type="hidden" name="date" value={amount.eptResponse.tranDate} />
            <input type="hidden" name="montant" value={amount.eptResponse.amount + amount.eptResponse.currency} />
            <input type="hidden" name="reference" value={amount.eptResponse.reference} />
            <input type="hidden" name="MAC" value={amount.eptResponse.mac} />
            <input type="hidden" name="url_retour_ok" value={amount.eptResponse.urlOk} />
            <input type="hidden" name="url_retour_err" value={amount.eptResponse.urlNotOk} />
            <input type="hidden" name="lgue" value={amount.eptResponse.language} />
            <input type="hidden" name="societe" value={amount.eptResponse.companyCode} />
            <input type="hidden" name="texte-libre" value={amount.eptResponse.textelibre} />
            <input type="hidden" name="mail" value={amount.eptResponse.email} />
            <input type="hidden" name="nbrech" value="" />
            <input type="hidden" name="dateech1" value="" />
            <input type="hidden" name="montantech1" value="" />
            <input type="hidden" name="dateech2" value="" />
            <input type="hidden" name="montantech2" value="" />
            <input type="hidden" name="dateech3" value="" />
            <input type="hidden" name="montantech3" value="" />
            <input type="hidden" name="dateech4" value="" />
            <input type="hidden" name="montantech4" value="" />
          </form>
        }
      </div>
      {showInfoPopUp && (
        <ConfirmPopup
          handleCancel={() => setShowInfoPopup(false)}
          text={i18next.t('modal.2')}
          title={""}
          footer={false}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: any): Partial<Props> => {
  return {
    discount: getDiscount(state),
    promotionDiscount: getPromotionDiscount(state),
    menuList: getMenu(state),
    loading: getLoading(state),
    brandDetail: getBrandDetail(state),
    deliveryMethod: getDeliveryMethod(state),
    amount: getAmount(state),
    encriptAmount: getEncriptAmount(state),
    order: getCreateOrder(state),
    user: getUser(state),
  }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return { dispatch }
}

export const PaymentBlock = connect(mapStateToProps, mapDispatchToProps)(withRouter(Payment));
