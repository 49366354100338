import {createReducer} from 'reduxsauce';
import {ProductsTypes} from "./actions";

export interface IProductsState {
    products: object,
    loading: boolean,
    loadMoreData: boolean,
    error: string,
    selectedMenuItemId?: any,
    filters: any,
    menuId: any
}

export const productsR: IProductsState = {
    products: {},
    error: "",
    loading: false,
    loadMoreData: true,
    selectedMenuItemId: "",
    filters: {},
    menuId: ""
};


export const products = (state) => {
    return {...state, loading: true};
};

export const productsSuccess = (state, action) => {
    const loadMoreData = action.data.catalogue.content && action.data.catalogue.totalElements !== action.data.catalogue.content.length;
    if(!action.pageSize || state.menuId !== action.selectedMenuId){
        state.products = {}
    }

    if (state.products.catalogue && state.products.catalogue.content && action.data.catalogue.content && action.data.catalogue.content.length ) {
        action.data.catalogue.content = [...state.products.catalogue.content, ...action.data.catalogue.content];
    }

    return {
        ...state,
        products: action.data,
        loading: false,
        selectedMenuItemId: action.selectedMenuId,
        loadMoreData,
        filters: action.data.filters,
        menuId: action.selectedMenuId
    };
};

export const productsFailure = (state, action) => {
    return {...state, error: action.error, products: {}, loading: false};
};

export const handlers = {
    [ProductsTypes.PRODUCTS_START]: products,
    [ProductsTypes.PRODUCTS_SUCCESS]: productsSuccess,
    [ProductsTypes.PRODUCTS_FAILURE]: productsFailure,
};

export default createReducer(productsR, handlers);