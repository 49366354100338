import { language } from './../../../layouts/main/header/redux/reducers';
import {PaymentTypes, PaymentActionCreators} from "./actions";
import {takeLatest, put, call, all, select} from "redux-saga/effects";
import axios from 'axios';
import {GET_ORDER_AMOUNT, ORDER_MAC, REDEEM_STORE_LOYALTY, WEB_CLIENT} from "../../../utils/constants/service";
import {getBasketData, getDateNow} from "../../../helpers/helpers"
import {BRAND_ID} from "../../../utils/constants/variables";
import { UserActionCreators } from "../../login/redux/actions";
import { OrderActionCreators } from "../../basket/redux/actions";
import { getBasket } from "../../addToCart/redux/selectors";
import { getBrandDetail } from "../../../layouts/main/header/redux/selectors";
import { getMesAddress } from '../../monEspace/container/components/MesAddress/redux/selectors';
import { getUserInfo } from '../../login/redux/selectors';

export function* amount(params) {
    const {day, month, year, hours, minutes, seconds} = getDateNow();
    const tranDate = `${day}/${month}/${year}:${hours}:${minutes}:${seconds}`;
    const { language, paymentGateway } = yield select(getBrandDetail);
    const addresses = yield select(getMesAddress);
    const userDetails = yield select(getUserInfo)
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        };
        const amount = yield call(() => {
            return axios.get(`${GET_ORDER_AMOUNT}${params.orderId}/amount`, headers)
                .then(res => res.data)
        });
        const activeBilling = addresses && addresses.length && addresses.find(address => address.addressType === 'BILL' && address.isDefault)
        const activeHipping = addresses && addresses.length && addresses.find(address => address.addressType === 'SHIP' && address.isDefault)
        const toBaseAddresses = {}
       
        if (activeBilling) {
            toBaseAddresses['billing'] = {
                "firstName": userDetails.firstName || '',
                "lastName": userDetails.lastName || '',
                "addressLine1": activeBilling.address1 || '',
                "city": activeBilling.city || '',
                "postalCode": activeBilling.zip || '',
                "country": activeBilling.countryCode || ''
            }
        }

        if (activeHipping) {
            toBaseAddresses['shipping'] = {
                "firstName": userDetails.firstName || '',
                "lastName": userDetails.lastName || '',
                "addressLine1": `${activeHipping.address1}`.replace(/[’]/g, " ") || '',
                "city": activeHipping.city || '',
                "postalCode": activeHipping.zip || '',
                "country": activeHipping.countryCode || ''
            }
        }

        const body = {
            "tranDate": tranDate,
            "amount": amount,
            "currency":"EUR",
            "reference": Math.random().toString().slice(2,10),
            "orderId": params.orderId,
            "email": params.email,
            "brandId":BRAND_ID,
            "language": language,
            "gateway": paymentGateway,
            "contexteCommande": btoa(JSON.stringify(toBaseAddresses)) || ''
        };

        let data = yield call(() => {
            return axios.post(`${ORDER_MAC}`, body, headers)
                .then(res => res.data)
        });

        data = {...data,encriptAmount:amount}
        yield put(PaymentActionCreators.amountSuccess(data));
    } catch (e) {
        console.log(e)
        yield put(PaymentActionCreators.amountFailure(e.message));
    }
}

export function* createClientLoyalty() {
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        };

        const data = yield call(() => axios.put(`${REDEEM_STORE_LOYALTY}?brandId=${BRAND_ID}`, {}, headers).then(res => res.data));

        const userInfo = yield call(() => {
            return axios.get(WEB_CLIENT, headers)
                .then(res => res.data)
        });

        const discountCode = userInfo.discountCodes && userInfo.discountCodes[userInfo.discountCodes.length - 1]

        yield put(UserActionCreators.userInfoSuccess(userInfo));
        yield put(OrderActionCreators.validateDiscountStart(discountCode));
        yield put(PaymentActionCreators.createClientDiscountSuccess(data));
    } catch (error) {
        yield put(PaymentActionCreators.createClientDiscountFailure(error));
    }
}


export function* paymentSaga() {
    yield takeLatest(PaymentTypes.AMOUNT_START, amount);
    yield takeLatest(PaymentTypes.CREATE_CLIENT_DISCOUNT_START, createClientLoyalty);
}

export default paymentSaga;
