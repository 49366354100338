import React, { Component } from "react";
import { connect } from "react-redux";
import i18next from "i18next";
import { getLocationSearch, getError } from '../redux/selectors';
import { LocationSearchActionCreators } from '../redux/actions';
import { bindActionCreators } from "redux";
import { ILocationSearchState } from "../redux/reducers"
import "./locationSearch.scss"
import { STORE_DELIVERY, DEFAULT_LOCATION, STORE_DEPOT } from "../../../utils/constants/variables";
import { getMenu } from "../../../layouts/main/header/redux/selectors";
import { getbasketLineItems } from "../../../helpers/helpers";


interface Props extends ILocationSearchState {
    locationSearchData: any,
    error: any,
    onClick?: any,
    classN?: string,
    basketLineItems?: any,
    product?: any,
    selectedSize?: any,
    selectedQuantity?: any,
    onSubmit?: (val) => void,
    shippingCity?: string | number,
    menu: any[],
}

interface State {
    value: string
}

class LocationSearchInput extends Component<Props & typeof LocationSearchActionCreators, State> {
    state: State = {
        value: ""
    };

    componentDidMount() {
        if (this.props.shippingCity) {
            this.setState({ value: this.props.shippingCity },
                () => this.handleClick()
            )
        }
    }

    handleClick = () => {
        const location = this.state.value || DEFAULT_LOCATION;
        const { basketLineItems, onSubmit, menu } = this.props;
        const products = getbasketLineItems();

        if (onSubmit) {
            onSubmit(location);
            return;
        }

        if ((STORE_DELIVERY === "1" && basketLineItems) && STORE_DEPOT !== "1") {
            // when user click on Trouvez un magasin ayant ce produit button or localize-moi from nearest store popuop
            this.props.clickAndCollectStoresStart({
                location,
                body: { basketLineItems },
                queryParams: { consignment: true, shipment: false }
            })
        } else if (basketLineItems || products && products.length) {
            this.props.clickAndCollectStoresStart({
                location,
                body: { basketLineItems: products },
                queryParams: { consignment: true, shipment: false }
            })

        }
        else {
            this.props.locationSearchStart(location);
        }

        this.props.onClick && this.props.onClick();
    };
    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.handleClick();
        }
    };

    handleChange = (e) => {
        this.setState({ value: e.target.value })
    };

    render() {
        const { classN, showButton, shippingCity } = this.props;
        const { value } = this.state;

        if (shippingCity) {
            return null
        }

        return (

            <>
                <label htmlFor="location">
                    <p className="locationSpan">{i18next.t('common.address')}</p>
                </label>
                <input
                    id="location"
                    value={value}
                    type="text"
                    placeholder={i18next.t('common.address')}
                    className="location-wrapper-item-search-input"
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                />

                {showButton ?
                    <button
                        className={`location-wrapper-item-search-btn ${value.length > 3 ? "" : "disabled"}`}
                        disabled={!(value.length > 3)}
                        onClick={this.handleClick}
                    >
                        {i18next.t("locator.2")}
                    </button>
                    :
                    <div className={classN ? classN : "search-content-input-btn"}>
                        <div className="search-icon footerSearchIcon" onClick={this.handleClick}></div>
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        locationSearchData: getLocationSearch(state),
        error: getError(state),
        menu: getMenu(state),
    }
};

const mapDispatchToProps = (dispatch): typeof LocationSearchActionCreators => {
    return bindActionCreators(LocationSearchActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSearchInput);

