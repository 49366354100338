import React, {Component} from "react";
import {connect} from 'react-redux'
import {bindActionCreators} from "redux";
import i18next from "i18next";
import {StoreLocationActionCreators} from '../redux/actions'
import {getUserStore} from "../redux/selectors";
import {dateStringFormat, addDays, setIsAnuller} from "../../../helpers/helpers";
import StoreLocator from "./storeLocator";
import {getBasket} from "../../addToCart/redux/selectors";
import {DISPATCH_TIME, STORE_DELIVERY, STORE_DEPOT} from "../../../utils/constants/variables";

interface IProps {
    userStore: any,
    selectedStore?: any,
    storeDelivery?: any,
    basketStore?: any,
    classN?: string,
    text?: string,
    isReturn?: boolean,
    textBtn?: string,
    from?: string;
    handleClick: ()=> void,
    setActivePrice?: (number: string) => void;
    changeSelectedPrice?: (num: string, method: string) => void
}

interface IState {
    isShow: boolean,
    annuler: string | null
}

class ChooseNearestStore extends Component <IProps & typeof StoreLocationActionCreators, IState> {

    state: IState = {
        isShow: false,
        annuler: localStorage.getItem('isAnulled') || 'notanulled'
    };

    changeAnullar(value) {
        const val = setIsAnuller(value);
        this.setState({annuler: val})
    }

    toggleStoreLocator = () => {
        this.setState({isShow: !this.state.isShow});
        this.props.locationSearchDestroyedStart();
    };

    anullerStore (event) {
        event.stopPropagation();
        this.props.changeSelectedPrice("1");
        this.changeAnullar('anulled');
    }

    render() {
        const {
            userStore,
            classN,
            text,
            isReturn,
            textBtn,
            selectedStore,
            basket,
            storeDelivery,
            from
        } = this.props;

        const { isShow } = this.state;
        const store = selectedStore ? selectedStore : userStore;

        return (
            <>
                { isShow && (
                    <StoreLocator
                        from={from}
                        changeAnullar={this.changeAnullar.bind(this)}
                        textBtn={textBtn}
                        toggleStoreLocator={this.toggleStoreLocator}
                        product={isReturn ? {} : basket}
                        isPayment={!isReturn}
                    />
                )}
                <div
                    onClick={this.toggleStoreLocator}
                    className={`choose-nearest-store card-block
                        ${classN || ""}
                        ${STORE_DEPOT === "1" && STORE_DELIVERY === "0" && storeDelivery === "DEP" ? ' active' : ''}
                        ${(storeDelivery === "STO" && from === 'deliveryPayment') ? 'bg-white' : ''}`}
                >
                    { storeDelivery !== "DIS" &&
                      store.storeId &&
                      (this.state.annuler === 'notanulled' ||
                      localStorage.getItem('isAnulled') === 'notanulled') ?
                        <>
                            <h4 className="storeName">{store.name}</h4>
                            <h4>{store.address}</h4>
                            <h4>{`${store.zip} ${store.city}`}</h4>
                            { store.phone ? <h4> Tél: {store.phone}</h4> :""}
                            {from !== 'deliveryPayment' && (STORE_DEPOT === "1"
                                ? ""
                                : storeDelivery==="STO"
                                    ? <h4>{`${i18next.t('locator.1')} ${dateStringFormat("", addDays(new Date(), 1))}`}</h4>
                                    : storeDelivery==="DIS"
                                        ? <h4>{`${i18next.t('locator.10')} ${dateStringFormat("", addDays(new Date(), DISPATCH_TIME))}`}</h4>
                                        :"")
                            }
                        </> :
                        <h4 className={`${STORE_DEPOT === "1" ? "bold" : ""}`}>{text ? text : STORE_DEPOT === "1" ? i18next.t('locator.13') : i18next.t('locator.4')}</h4>
                    }

                    {(from === "deliveryPayment" && this.state.annuler === 'notanulled' && localStorage.getItem('isAnulled') || "") && (
                        <button className="main-btn nearestStore" onClick={this.anullerStore.bind(this)}>{i18next.t("space.address.8")}</button>
                    )}
                 </div>
            </>
        );
    }
}

const mapStateToProps = (state: any): IProps & typeof StoreLocationActionCreators => {
    return {
        userStore: getUserStore(state),
        basket: getBasket(state),
    }
};
const mapDispatchToProps = (dispatch): typeof StoreLocationActionCreators => {
    return bindActionCreators(StoreLocationActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseNearestStore);
