import axios from "axios";
import { getApiPrefix, getAxiosHeaders } from "../../helpers/helpers";
import { ALT_BRAND_ID, BRAND_ID, COUNTRY, IPSTACK_KEY } from "./variables";

// export const GOOGLE_MAP_KEY = "AIzaSyBBHFO7wFPBehWHSzQCk9mkojZbAAVqInY";
export const BASE_URL = process.env.REACT_APP_EXTERNAL_GATEWAY_URL ? process.env.REACT_APP_EXTERNAL_GATEWAY_URL : process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : process.env.PUBLIC_URL;
export const EXTERNAL_GATEWAY_URL_PREFIX = `/externalgateway`;
export const ROOT_URL = window.location.origin;
export const BASE_API_GATEWAY = process.env.REACT_APP_EXTERNAL_GATEWAY_URL ? process.env.REACT_APP_EXTERNAL_GATEWAY_URL : (BASE_URL + EXTERNAL_GATEWAY_URL_PREFIX);

export const BASE_PRODUCT_SERVICE_URL = BASE_API_GATEWAY + `/productservice`;
export const PRODUCT_SERVICE_API_URL = (process.env.REACT_APP_PRODUCT_SERVICE_URL ? process.env.REACT_APP_PRODUCT_SERVICE_URL : BASE_PRODUCT_SERVICE_URL) + `/api/`;
export const PRODUCT_SERVICE_API_EXTERNAL_URL = PRODUCT_SERVICE_API_URL + `external/`;

export const BASE_API_MESSAGINGSERVICE_URL = BASE_API_GATEWAY + `/messagingservice`;
export const BASE_API_MESSAGINGSERVICE = (process.env.REACT_APP_MESSAGING_SERVICE_URL ? process.env.REACT_APP_MESSAGING_SERVICE_URL : BASE_API_MESSAGINGSERVICE_URL) + `/api/`;
export const DOWNLOAD_ID = PRODUCT_SERVICE_API_URL + "files/download/invoice/";
export const MENU_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + "menu";
export const SEARCHING_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + "search";
export const CONTENT_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + "content/grid/";
export const PRODUCTS_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + "product/catalogue/menu/";
export const PRODUCT_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + "product/";
export const PRODUCT_CARE_URL = PRODUCT_URL + "attributes/care/";
export const PRODUCT_COMPOSITION_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + "product/attributes/composition/";
export const PRODUCT_CANONICAL_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + "product/canonical-url/";
export const CONTACTUS_URL = BASE_API_MESSAGINGSERVICE + "external/tickets/";
export const CONTACTUS_SEND_URL = BASE_API_GATEWAY + "/api/external/tickets";
export const RECOMMENDED_PRODUCTS_URL = BASE_API_GATEWAY + "/api/external/clickstream/recommended-products";
export const MAILJET_SUBSCRIBE_URL = BASE_API_GATEWAY + "/api/mailinglist/subscribe";
export const NEW_MAILJET_SUBSCRIBE_URL = BASE_API_GATEWAY + "/api/external/azure/zg-newsletter-create"
export const CANONICAL_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + "product/canonical-url/";
export const LOCATIONSEARCH_URL = BASE_API_GATEWAY + "/storeservice/api/external/store/nearest/";
export const LOCATIONSEARCH_URL_POST = BASE_URL + "/api/external-secured/stores/click-and-collect-stores";
export const WEB_CLIENT = BASE_API_GATEWAY + "/api/external-secured/web-clients";
export const WEB_CLIENT_SEND_URL = BASE_API_GATEWAY + "/api/external-secured/web-clients";
export const USER_UPDATE_URL = BASE_API_GATEWAY + "/api/external-secured/user/update";
export const ORDER_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + "order";
export const WEB_ORDER = BASE_API_GATEWAY + "/api/external-secured/orders/by-web-client";
export const WEB_STORE_SALES = BASE_API_GATEWAY + "/api/external-secured/web-clients/store-sales/by-web-client";
export const WEB_CLIENT_ADDRESS = BASE_API_GATEWAY + "/api/external-secured/client-address";
export const WEB_DISCOUNT_VALIDATE_NO_LOGIN = BASE_API_GATEWAY + "/api/external/web-discount/validate-discount";
export const CREATE_ORDER = BASE_API_GATEWAY + "/api/external-secured/orders/create";
export const UPDATE_ORDER = BASE_API_GATEWAY + "/api/external-secured/orders/";
export const GET_ORDER_AMOUNT = BASE_API_GATEWAY + "/api/external-secured/orders/";
export const ORDER_MAC = BASE_API_GATEWAY + "/api/external-secured/mac";
export const STORES_BRAND_URL = BASE_API_GATEWAY + "/storeservice/api/external/store/by-brand";
export const ACCOUNT_REGISTER_URL = BASE_API_GATEWAY + "/api/register";
export const USER_AUTHENTICATE_URL = BASE_API_GATEWAY + "/api/authenticate";
export const USER_ACTIVATION_URL = BASE_API_GATEWAY + "/api/activate?key=";
export const EMAIL_REGISTRATION_CHECK_URL = BASE_API_GATEWAY + "/api/checkemail";
export const PASSWORD_RESET_URL = BASE_API_GATEWAY + "/api/account/reset-password/init?legacyUser=";
export const PASSWORD_RESET_FINISH_URL = BASE_API_GATEWAY + "/api/account/reset-password/finish";
export const PAYMENT_ACCEPTED_URL = BASE_API_GATEWAY + "/api/external-secured/accepted?id=";
export const PAYMENT_FAILED_URL = BASE_API_GATEWAY + "/api/external-secured/failed?id=";
export const PASSWORD_CHANGE_URL = BASE_API_GATEWAY + "/api/account/change-password";
export const INSTAGRAM_DATA_URL = BASE_API_GATEWAY + "/api/instagram/brandId/";
export const BANNER_TEXT = BASE_PRODUCT_SERVICE_URL + "/api/external/brands";
export const BRAND_CATALOGUES_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + "brand-catalogues/by-brand/";
export const CREATE_USER_PAYMENT_PAYPAL = BASE_API_GATEWAY + "/api/external-secured/create-user-payment";
export const CLICK_AND_COLLECT_STORES_URL = BASE_API_GATEWAY + "/storeservice/api/external/stores/click-and-collect-stores";
export const SUBSCRIPTION_URL = BASE_API_GATEWAY + "/api/mailinglist/subscribe";
export const GET_STORES_DATA = BASE_API_GATEWAY + "/api/external/stores/stores-by-brand-id";
export const ZENDESK_API = BASE_API_GATEWAY + "/billingpaymentservice/api/external/create-zendesk-ticket";
export const BRAND_DETAILS_URL = BASE_API_GATEWAY + "/api/external/brands/details";
export const REDEEM_STORE_LOYALTY = WEB_CLIENT + '/redeem-store-client-loyalty';
export const ZG_CUSTOMER_CREATE = BASE_API_GATEWAY + '/api/external-secured/azure/zg-customer-create';
export const GET_BARCODE_BY_PRODUCT_CLUSTER_ID = BASE_URL + '/api/external/product/get-barcodes-by-product-cluster-id';

export const fetchClickAnCollectStores = async (body, location, queryParams) => {
  let country_code = localStorage.country
  let url = `${CLICK_AND_COLLECT_STORES_URL}?brandId=${ALT_BRAND_ID || BRAND_ID}&language=${getApiPrefix()}&country=${country_code ? (country_code === 'MC' ? 'FR' : country_code) : COUNTRY}&location=${location}`

  if (queryParams !== null) {
    url += queryParams.consignment ? `&consignment=${queryParams.consignment}` : `&shipment=${queryParams.shipment}`;
  }
  return await axios.post(url, body).then((res) => res.data);
}

export const fetchIpstackData = async () =>
  await axios.get(`https://api.ipstack.com/check?access_key=${IPSTACK_KEY}`).then(
    (res: any) => {
      if (res.success === false) throw 'error';
      return res.data;
    },
  );

export const validateDiscount = (discountCode, webClientId) => {
  const headers = {
    headers: {
      Authorization: localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "",
      'Content-Type': 'application/json',
    }
  };

  return axios.get(`${WEB_DISCOUNT_VALIDATE_NO_LOGIN}?discountCode=${discountCode}&brandId=${BRAND_ID}&webClientId=${webClientId}`, headers)
    .then(res => res.data)
}

export const getClusterBarcodesById = (clusterId = 0) => {
  return axios
    .get(`${GET_BARCODE_BY_PRODUCT_CLUSTER_ID}?productClusterId=${clusterId}`, getAxiosHeaders()).then((res) => res.data);
}

export const getNotFoundPageData = async (gridId) => {
  const headers = { headers: { brand: BRAND_ID, language: getApiPrefix() } };
  return await axios.get(`${CONTENT_URL}${gridId}`, headers).then((res) => res.data);
}