import React, { Component } from "react";
import i18next from "i18next";
import PaypalBtn from 'react-paypal-checkout';
import ResponseMessage from "../../responseMessage/container";
import DiscountPayment from "../../basket/container/components/discountPayment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Checkbox, Icon } from 'semantic-ui-react'
import { Link, Redirect } from "react-router-dom"
import { PaymentActionCreators } from "../redux/actions";
import { getCreateOrder, getDeliveryMethod, getDiscount, getPromotionDiscount } from "../../basket/redux/selectors";
import "./payment.scss";
import {
    getCurrency,
    getBasketData,
    replaceComma,
    getLangPrefix,
    dataLayerPush,
    compareBaskets,
    makeThousandFormat,
    getConvertedItemDataForGA4,
    tooFixed,
    getLocalStorageObject,
    getMenuItemByMenuIdElem
} from "../../../helpers/helpers";
import { getBrandDetail, getMenu, getProductClusterPackBarcodes, getUser } from "../../../layouts/main/header/redux/selectors";
import {
    DEFAULT_CURRENCY,
    DISCOUNT_CODE,
    PAYPAL_CLIENT_ID, PAYPAL_ENV,
    REDEEM_POINTS,
    STORAGE_BASKET_INDEX,
    STORE_DEPOT,
    VALIDATE_DISCCOUNT,
    BRAND_ID
} from './../../../utils/constants/variables';
import { getAmount, getError, getStatus, getLoading, getEncriptAmount } from "../redux/selectors";
import { getUserInfo, isAuthenticated } from "../../login/redux/selectors";
import { STORE_DELIVERY } from '../../../utils/constants/variables';
import { getUpdateStatus } from "../../basket/redux/selectors";
import { getPaypalData } from "../../validation/redux/selectors";
import { MenuActionCreators } from "../../../layouts/main/header/redux/actions";
import { StoreLocationActionCreators } from "../../location/redux/actions";
import { OrderActionCreators } from "../../basket/redux/actions";
import { TrackPurchase } from "../../../helpers/ga4Tracker";

interface Props {
    basket?: any,
    order: any,
    menuList?: any,
    amount: any,
    error: string,
    status: string,
    loading: boolean,
    user: any,
    authenticated: boolean,
    getUpdateStatus: string,
    deliveryMethod: string,
    encriptAmount: string,
    paypalInfo: any,
    payRef?: React.RefObject<any>,
    confirm?: any;
    discount: any;
    promotionDiscount: any;
    detail: any;
    selectedStore?: any;
    shippingAddress?: any;
    webClient: any;
    productClusterBarcodes: any;
}

interface State {
    accept: boolean,
    paymentForm: any,
    showMessage: boolean,
    emptyOrder: boolean,
    isCheOrder: boolean,
    accepted: string,
    showForm: boolean,
    payPalData: any,
    paypalLoading: boolean,
    cgvMenu: {
        canonicalUrl: string,
        label: string
    },
    isPaymentCheckedAllowed: boolean;
    isChecked: boolean;
}

class Payment extends Component<Props & typeof PaymentActionCreators> {

    state: State = {
        accept: false,
        isCheOrder: true,
        paymentForm: {},
        showMessage: false,
        emptyOrder: false,
        accepted: "start",
        showForm: false,
        payPalData: {},
        paypalLoading: false,
        cgvMenu: {
            canonicalUrl: '',
            label: ''
        },
        isPaymentCheckedAllowed: true,
        isChecked: false
    };

    formRef: any = React.createRef();
    componentDidMount(): void {
        const { basket } = this.props;
        const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];
        if (storeDelivery === "STO") {
            this.props.selectedDeliveryMethodStart("storeDelivery")
        } else {
            this.props.selectedDeliveryMethodStart("onlineDelivery")
        }

        if (Array.isArray(this.props.menuList)) {
            this.setState({ cgvMenu: this.props.menuList.find(item => item.altUrl1 === "cgv") || {} })
        }
    }

    componentWillReceiveProps(nextProps) {
        const { basket } = this.props;
        if (
            nextProps.amount.eptResponse
            && (
                !this.props.amount.eptResponse
                || this.props.amount.eptResponse
                && this.props.amount.eptResponse.mac !== nextProps.amount.eptResponse.mac
            )
        ) {
            this.setState({ paymentForm: nextProps.amount })  //Show Paypal button, don't redirect directly to Monetico
            const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];
            if (storeDelivery === "STO") {
                this.props.selectedDeliveryMethodStart("storeDelivery")
            } else {
                this.props.selectedDeliveryMethodStart("onlineDelivery")
            }
        }

        if (nextProps.status && nextProps.status !== this.props.status) {
            setTimeout(() => {
                this.setState({ showMessage: false })
            }, 7000)
        }

        if (nextProps.order && nextProps.order.length && ["AWA", "CHE"].includes(nextProps.order[0].orderStatus) && (!this.props.order[0] || nextProps.order[0].orderId !== this.props.order[0].orderId)) {
            this.setState({ isCheOrder: false })
        }
        if (this.props.getUpdateStatus !== nextProps.getUpdateStatus) {
            if (nextProps.getUpdateStatus === "success") {
                this.setState({ accepted: "success" });
            } else if (nextProps.getUpdateStatus === "failed") {
                this.setState({ accepted: "failed" })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { basket, order, deliveryMethod, discount, shippingAddress, selectedStore } = this.props;
        const { basket: prevBasket, discount: prevDiscount } = prevProps;
        const { accept, isCheOrder, isPaymentCheckedAllowed, isChecked } = this.state;
        const isAnulled = localStorage.getItem("isAnulled");
        const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];
        const prevStoreDelivery = prevProps.basket["store"] && prevProps.basket["store"]["storeDelivery"];
        const disabled =
            !accept
            || isCheOrder
            || !(order && order[0])
            || (
                storeDelivery === "STO"
                && deliveryMethod === "storeDelivery"
                && (STORE_DEPOT === "1" && STORE_DELIVERY === "1"));

        if (prevProps.order[0] && !this.props.order[0]) {
            this.setState({ paymentForm: {} })
        }

        if (!prevState.showform && this.state.showForm && this.props.order[0]) {
            this.formRef.submit()
        }

        if (!storeDelivery && prevStoreDelivery && isChecked) {
            this.hendleChangeCeckBoxCheked(null, { checked: false })
        }

        if (this.props.paypalInfo && (this.props.paypalInfo.status && this.props.paypalInfo.status !== prevProps.paypalInfo.status)) {
            if (this.props.paypalInfo.status.toUpperCase() === "SUCCESS") {
                const orderStatus = basket && basket.store
                    && basket.store.storeDelivery === 'DIS'
                    && basket.store.storeType === 'E' ? 'PAI' : 'STO';
                if (orderStatus === 'PAI' || orderStatus === 'STO' || orderStatus === 'DIS') {
                    const { response: { transactionId } } = this.props.paypalInfo
                    if (transactionId) {
                        const { price } = getBasketData(basket);
                        const items = [] as any
                        const vDiscount = getLocalStorageObject(VALIDATE_DISCCOUNT)
                        const { value = 0 } = vDiscount
                        const dValue = tooFixed(value > 0 ? price - (price / value) : price, 2)
                        Object.keys(basket).map((item: any) => {
                            if (item !== 'store') {
                                const basketMenuItem = getMenuItemByMenuIdElem(this.props.menuList, basket[item].menuId)
                                basket[item].sizes && basket[item].sizes.length && basket[item].sizes.map((sizeElement, i) => {
                                    const dataT = getConvertedItemDataForGA4(this.props.menuItem || basketMenuItem, basket[item], sizeElement.quantity && sizeElement.quantity > 0 ? sizeElement.quantity : 1, sizeElement);
                                    dataT.location_id = 'paypal'
                                    items.push(dataT)
                                })
                            }
                        })
                        TrackPurchase(items, dValue)
                    }
                }
                this.setState({ payPalData: { ...this.state.payPalData, paypalStatus: "accepted" } })
                localStorage.removeItem(STORAGE_BASKET_INDEX);
                localStorage.removeItem(VALIDATE_DISCCOUNT);
                localStorage.removeItem(DISCOUNT_CODE);
                localStorage.removeItem("freight_cost")
            } else if (this.props.paypalInfo.status.toUpperCase() === "ERROR") {
                this.setState({ payPalData: { paypalStatus: "failed" } })
            }
        }

        if (
            !disabled && (compareBaskets(prevBasket, basket)
                || (prevDiscount && discount && discount.webDiscountId !== prevDiscount.webDiscountId))
        ) {
            this.props.confirm();
        }

        if (shippingAddress.country !== "FRANCE" && isAnulled === "anulled" && isPaymentCheckedAllowed) {
            this.setState({ isPaymentCheckedAllowed: false, isChecked: false, accept: false });
        }

        if (!isPaymentCheckedAllowed && ["FR", "FRANCE"].includes(shippingAddress.country) && isAnulled === "anulled") {
            this.setState({ isPaymentCheckedAllowed: true });
        }

        if (
            selectedStore &&
            (selectedStore.country === "FRANCE" || selectedStore.country === "FR")
            && isAnulled !== "anulled" && !isPaymentCheckedAllowed
        ) {
            this.setState({ isPaymentCheckedAllowed: true });
        }
    }

    componentWillUnmount() {
        this.setState({
            accept: false,
            isCheOrder: true,
            paymentForm: {},
            showMessage: false,
            emptyOrder: false,
            accepted: "start",
            showForm: false,
            payPalData: {},
            paypalLoading: false,
        })
    }

    handleSubmitStoreDelivery = () => {
        const { order, user, deliveryMethod } = this.props;
        const { email } = user;
        const { basket } = this.props;
        const orderStatus = basket && basket.store
            && basket.store.storeDelivery === 'DIS'
            && basket.store.storeType === 'E' ? 'PAI' : 'STO';
        if (STORE_DELIVERY === "1" && deliveryMethod === "storeDelivery" && order && order[0]) {
            const orderId = order[0].orderId
            const params = { orderId, orderStatus, email }
            this.props.updateOrderStart(params)
        } else {
            return false;
        }
    };

    handleSubmit = () => {
        const { order, user } = this.props;
        const { email } = user;
        const { paymentForm } = this.state;
        if (paymentForm.status && paymentForm.status.toUpperCase() === "SUCCESS") {
            this.setState({ showForm: true });
            return;
        }

        const item = order && order.length ? order.find(item => item.orderStatus === "CHE") : {};
        const orderId = item.orderId;

        if (orderId) {
            this.props.amountStart(orderId, email)
        } else {
            this.setState({ emptyOrder: true })
        }
        this.setState({ showMessage: true })
    };

    hendleChangeCeckBoxCheked = (_, input) => {
        if (input.checked) {
            this.props.confirm();
        }

        this.setState({ accept: !this.state.accept, payPalData: {}, isChecked: input.checked })
    };

    removeValidateDiscount = () => {
        localStorage.removeItem(DISCOUNT_CODE);
        this.props.annullerDiscountStart();
        this.props.getBasketStart();
    }

    render() {
        const { basket, status, error, loading, amount, order, deliveryMethod, encriptAmount, user, promotionDiscount, detail, webClient, discount } = this.props;
        const { loyaltyPoints, loyaltyValue, currency } = webClient.storeClientLoyalty || { loyaltyPoints: -Infinity, loyaltyValue: -Infinity, currency: "EUR" };
        const treshold = detail.conversionThreshold || Infinity;
        const mmultiple = detail.conversionMultiple || 0;
        const { accept, paymentForm, showMessage, emptyOrder, isCheOrder, accepted, showForm, payPalData, paypalLoading, cgvMenu, isPaymentCheckedAllowed, isChecked } = this.state;
        const parsedDiscount = discount;
        const currentAmount = amount && amount.eptResponse && amount.eptResponse.amount;
        const { price, totalPrice, livraison, totalQuantity, oldPrice, discountedSum } = getBasketData(basket, parsedDiscount);
        const isStoreDeliveryMethod = deliveryMethod === "storeDelivery";
        const createdOrderId = order && order[0] && order[0].orderId ? order[0].orderId : null;
        const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];
        if (accepted === "success") {
            const state = {
                id: order && order[0] && order[0].orderId,
                BaskstStore: storeDelivery ? basket["store"] : ""
            };
            return (<Redirect to={{ pathname: `${getLangPrefix()}/accepted`, state }} />)
        }
        if (payPalData && payPalData["paypalStatus"] === "accepted") {
            return (<Redirect to={{ pathname: `${getLangPrefix()}/accepted-paypal`, state: { payPalData: { ...payPalData, createdOrderId }, BaskstStore: storeDelivery ? basket["store"] : "" } }} />)
        } else if (payPalData && payPalData["paypalStatus"] === "failed") {
            return (<Redirect to={{ pathname: `${getLangPrefix()}/failed-paypal`, state: { payPalData: { ...payPalData, createdOrderId } } }} />)
        }
        const disabled = !accept || isCheOrder || !(order && order[0]) || (storeDelivery === "STO" && deliveryMethod === "storeDelivery" && (STORE_DEPOT === "1" && STORE_DELIVERY === "1"))
        const checkFooterLine = !isStoreDeliveryMethod && (totalPrice - oldPrice) || '';
        const promotion = detail && detail.promotion && detail.promotion.length && detail.promotion[0];
        const ruleNameMessage = promotion && promotion.ruleName;
        const loyaltyPointsCalculated = makeThousandFormat(Math.trunc(loyaltyPoints / mmultiple) * mmultiple);
        const isPercentPromotion = ["AMOUNT", "QUANTITY", "PERCENTAGE"].includes(promotion && promotion.ruleType);

        return (
            <div className="peymant-content payment-section active">
                <div>
                    <div className="dflex justify-content-between flex-column peymant-content-item-box">
                        <DiscountPayment classN={"mobileSize768 mb_10"} />
                        {REDEEM_POINTS === '1' && loyaltyPoints >= treshold && (webClient && webClient.brandId) === BRAND_ID && (
                            <div className="peymant-content-item peymant-content-item-value">
                                <div className="peymant-content-item-value-wrapper">
                                    <div
                                        className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between"
                                    >
                                        <h4>
                                            {i18next.t('mesInformations.23')}:&nbsp;
                                            <span className="price">{loyaltyPoints} {`(${replaceComma(loyaltyValue)}${getCurrency()})`}</span>
                                        </h4>
                                    </div>
                                    <div
                                        className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between"
                                    >
                                        <h4>
                                            {i18next.t('product.basket.42')}:&nbsp;
                                            <span className="price">{loyaltyPointsCalculated || ''}</span>
                                        </h4>
                                    </div>
                                    <div
                                        className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between close"
                                    >
                                        {price < loyaltyValue ? (
                                            <h4>({i18next.t('product.basket.44')}: <span className="price">{replaceComma(loyaltyValue)}{getCurrency(currency)}</span>)</h4>
                                        ) : (
                                            <h4>({i18next.t('product.basket.43')}: <span className="price">{mmultiple}</span>)</h4>
                                        )}
                                    </div>
                                    <div className="d-center">
                                        <button
                                            className={`valider-button hoverButtonStyle ${price < loyaltyValue ? 'disabled' : ''}`}
                                            onClick={() => this.props.createClientDiscountStart({})}
                                            disabled={price < loyaltyValue}
                                        >
                                            {i18next.t('product.basket.11')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="peymant-content-item peymant-content-item-value">
                            <div className="peymant-content-item-value-wrapper">
                                <div
                                    className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
                                    <h4>{totalQuantity} {totalQuantity < 2 ? i18next.t('product.basket.23') : i18next.t('product.basket.12')}</h4>
                                    {
                                        isStoreDeliveryMethod ?
                                            <h2 className="price"> {replaceComma(price) + getCurrency()}</h2>
                                            :
                                            <h2 className="price"> {replaceComma(price)}{getCurrency()}</h2>

                                    }

                                </div>

                                {parsedDiscount.value &&
                                    <div
                                        className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
                                        <div className="validateDiscountWrapper">
                                            <h4> {parsedDiscount ? i18next.t('product.basket.21') + " " + `${parsedDiscount.discountLabel}`.toUpperCase() : ""}</h4>
                                            <span onClick={this.removeValidateDiscount} className="validateDiscount-trashicon">
                                                <Icon className='darkred' name="trash alternate"></Icon>
                                            </span>
                                        </div>
                                        <h2 className="price">-{parsedDiscount && parsedDiscount.couponType === "PERCENT" && replaceComma(parsedDiscount.value).split(",")[1] === "00" ? replaceComma(parsedDiscount.value).split(",")[0] : parsedDiscount ? replaceComma(parsedDiscount.value) : ""}{parsedDiscount.couponType === "PERCENT" ? "%" : "€"}</h2>
                                    </div>
                                }
                                {discountedSum ?
                                    <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
                                        <h4>{ruleNameMessage}</h4>
                                        <h2 className="price">-{isPercentPromotion ? discountedSum : replaceComma(discountedSum)}
                                            {isPercentPromotion ? '%' : '€'}
                                        </h2>
                                    </div> : ""
                                }
                                {
                                    !isStoreDeliveryMethod ?
                                        <div
                                            className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
                                            <h4>
                                                {livraison ?
                                                    i18next.t('product.basket.18') :
                                                    i18next.t('product.basket.17')
                                                }
                                            </h4>
                                            <h2 className="price"> {replaceComma(livraison) + getCurrency()}</h2>
                                        </div>
                                        : ""
                                }

                                {
                                    totalPrice - oldPrice ?
                                        <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
                                            <h4>{i18next.t('product.basket.27')}</h4>

                                            <h2 className="price"> {replaceComma(totalPrice - oldPrice) + getCurrency()}</h2>
                                        </div> : null
                                }
                            </div>
                            <div className={`peymant-content-item-value-footer dflex justify-content-between ${checkFooterLine && 'p-20'}`}>
                                <h4>{STORE_DEPOT === "1" ? i18next.t('product.basket.15') : storeDelivery === "STO" ? i18next.t('product.basket.31') : i18next.t('product.basket.15')}</h4>
                                <div>
                                    <h4 className="peymant-content-all-price">{replaceComma(totalPrice) + getCurrency()}</h4>
                                    <h4 className="comprise">{i18next.t('product.basket.16')}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="peymant-content-item peymant-content-item-code" ref={this.props.payRef}>
                            <h4 className={'text-uppercase'}>{i18next.t('payment.1')}</h4>
                            <Checkbox
                                onChange={this.hendleChangeCeckBoxCheked}
                                className="mainCheckbox"
                                disabled={!isPaymentCheckedAllowed}
                                checked={isChecked}
                                label={
                                    <label>{i18next.t('payment.2')}
                                        <Link className={'main-link ml_15'}
                                            to={getLangPrefix() + cgvMenu.canonicalUrl} target="_blank" >
                                            {cgvMenu.label}
                                        </Link>
                                    </label>
                                }
                            />
                            {
                                showMessage && status &&
                                <ResponseMessage
                                    status={status}
                                    errorText={error}
                                    successText="success"
                                />
                            }

                            <div>
                                <Button loading={loading && showForm}
                                    className={`paymentBtn hoverButtonStyle button-style Dflex align-items-center justify-content-center`}
                                    disabled={disabled || !Boolean(basket && Object.keys(basket).find(item => item !== 'store'))}
                                    onClick={this.handleSubmit}>
                                    <Icon className={"credit card outline icon iconWhited"} /> {i18next.t('payment.15')}
                                </Button>

                                {
                                    paypalLoading || (loading && showForm) ?
                                        <Button basic loading className="paymentBtn">
                                            Loading
                                        </Button> :
                                        <Button basic
                                            className={`paymentBtn  ${disabled ? "disabled" : ""}`}
                                            disabled={disabled || !Boolean(basket && Object.keys(basket).find(item => item !== 'store'))}
                                        >
                                            <PaypalBtn
                                                env={PAYPAL_ENV}
                                                client={{ [PAYPAL_ENV]: PAYPAL_CLIENT_ID }}
                                                currency={DEFAULT_CURRENCY}
                                                total={currentAmount ? Number(currentAmount) : 0}
                                                style={{
                                                    shape: 'rect',
                                                    size: 'responsive',
                                                    tagline: false,
                                                    color: 'gold',
                                                    layout: 'horizontal',
                                                    label: 'paypal',
                                                }}
                                                onSuccess={(payment) => {
                                                    dataLayerPush(createdOrderId, user && user.email)
                                                    const body = {
                                                        userEmail: payment.email,
                                                        transactionDate: new Date().toISOString(),
                                                        orderId: createdOrderId,
                                                        transactionId: payment.paymentID,
                                                        reference: payment.paymentToken,
                                                        amount: encriptAmount,
                                                        paymentAmount: currentAmount,
                                                        currency: DEFAULT_CURRENCY,
                                                        status: "PAYMENT_CONFIRMED",
                                                        ipAddress: "",
                                                        paymentMethod: "Paypal"

                                                    }

                                                    this.props.userPaymentPaypalStart(body);
                                                    this.setState({
                                                        payPalData: {
                                                            ...payment,
                                                            reference: payment.paymentToken
                                                        }, paypalLoading: true
                                                    })
                                                }}
                                                onError={() => {
                                                    this.setState({
                                                        payPalData: { paypalStatus: "failed" },
                                                        paypalLoading: true
                                                    })
                                                }}

                                                onCancel={() => {
                                                    this.setState({ paypalLoading: false })
                                                }} />

                                        </Button>
                                }
                            </div>

                            {accepted === "failed" ?
                                <div className="text-center">
                                    <ResponseMessage
                                        status={accepted}
                                        errorText={`${i18next.t('validation.15')}  ${i18next.t('validation.17')}`}
                                        successText="success"
                                    />
                                </div> : ""}

                        </div>
                    </div>

                    {emptyOrder && <p>Select address</p>}
                    {showForm && paymentForm && paymentForm.eptResponse &&
                        <form
                            name="MoneticoFormulaire"
                            ref={ref => { this.formRef = ref }}
                            target="_self"
                            action={`${paymentForm.eptResponse.url}/${paymentForm.eptResponse.urlPayment}`}
                        >
                            <input type="hidden" name="version" value={paymentForm.eptResponse.version} />
                            <input type="hidden" name="TPE" value={paymentForm.eptResponse.eptNumber} />
                            <input type="hidden" name="contexte_commande" value={paymentForm.eptResponse.contexteCommande} />
                            <input type="hidden" name="date" value={paymentForm.eptResponse.tranDate} />
                            <input type="hidden" name="montant"
                                value={paymentForm.eptResponse.amount + paymentForm.eptResponse.currency} />
                            <input type="hidden" name="reference"
                                value={paymentForm.eptResponse.reference} />
                            <input type="hidden" name="MAC" value={paymentForm.eptResponse.mac} />
                            {/* <input type="hidden" name="url_retour"
                                value={window.location.protocol + "//" + window.location.host + getLangPrefix() + "/failed/" + paymentForm.eptResponse.encryptedTextelibre} /> */}
                            <input type="hidden" name="url_retour_ok" value={paymentForm.eptResponse.urlOk} />
                            <input type="hidden" name="url_retour_err" value={paymentForm.eptResponse.urlNotOk} />
                            <input type="hidden" name="lgue" value={paymentForm.eptResponse.language} />
                            <input type="hidden" name="societe"
                                value={paymentForm.eptResponse.companyCode} />
                            <input type="hidden" name="texte-libre"
                                value={paymentForm.eptResponse.textelibre} />
                            <input type="hidden" name="mail" value={paymentForm.eptResponse.email} />
                            <input type="hidden" name="nbrech" value="" />
                            <input type="hidden" name="dateech1" value="" />
                            <input type="hidden" name="montantech1" value="" />
                            <input type="hidden" name="dateech2" value="" />
                            <input type="hidden" name="montantech2" value="" />
                            <input type="hidden" name="dateech3" value="" />
                            <input type="hidden" name="montantech3" value="" />
                            <input type="hidden" name="dateech4" value="" />
                            <input type="hidden" name="montantech4" value="" />
                        </form>
                    }
                </div>

            </div>
        );
    }
}


const mapStateToProps = (state: any): Props => ({
    menuList: getMenu(state),
    order: getCreateOrder(state),
    amount: getAmount(state),
    authenticated: isAuthenticated(state),
    error: getError(state),
    status: getStatus(state),
    loading: getLoading(state),
    user: getUser(state),
    getUpdateStatus: getUpdateStatus(state),
    deliveryMethod: getDeliveryMethod(state),
    encriptAmount: getEncriptAmount(state),
    paypalInfo: getPaypalData(state),
    discount: getDiscount(state),
    promotionDiscount: getPromotionDiscount(state),
    detail: getBrandDetail(state),
    webClient: getUserInfo(state),
    productClusterBarcodes: getProductClusterPackBarcodes(state)
});

const mapDispatchToProps = (dispatch): typeof PaymentActionCreators | typeof MenuActionCreators => {
    return bindActionCreators(Object.assign
        (
            {},
            MenuActionCreators,
            PaymentActionCreators,
            StoreLocationActionCreators, OrderActionCreators
        ), dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
